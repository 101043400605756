import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { verifySelect } from "./inputVerification"


function ChangeSelect(value, formInputs, setFormInputs, name) {
  setFormInputs({ ...formInputs, [name]: value });
}

const inputStyle = {
  height: 30,
}

function AutocompleteComponent(formInputs, setFormInputs, options, label, name, placeholder, elementErrorState, isSubmitted, disableHelperText) {
  return <FormControl className="formControlCSS" fullWidth sx={inputStyle}>
    <Autocomplete

      id={name}
      size="small"
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={(event, value) => {
        ChangeSelect(value.value, formInputs, setFormInputs, name);
        var inputVerification = verifySelect(value.value)
        elementErrorState.initialState = false;
        elementErrorState.error = !inputVerification.isValid
        elementErrorState.helperText = inputVerification.helperText
      }}
      renderInput={(params) => (
        <TextField {...params}
          error={isSubmitted && elementErrorState.error}
          helperText={!disableHelperText && isSubmitted && elementErrorState.error ? elementErrorState.helperText : ""}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          InputLabelProps={{ shrink: true}}
          placeholder={placeholder} />
      )}
    />
  </FormControl>;
}


export default AutocompleteComponent;
