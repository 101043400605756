import React, { useEffect } from "react";

function PrestigeTable(
  boxPrestige,
  onClickbox,
  activeDisplayRenfort,
  prestige,
  prestigeFinal,
  littleScreen
) {

  return (
    <div
      ref={boxPrestige}
      onClick={() => onClickbox("Prestige", prestigeFinal)}
      // onTouchStart={onClickboxPrestige}
      id="planPrestige"
      style={{ borderTop: "10px solid #509995" }}
      className="plan"
    >
      <div className="plan-cost">
        <h2>Prestige</h2>
        <button
          id="buttonRenfortPrestige"
          style={{
            // background: "#1C467E",
            position: "relative",
            zIndex: "100",
          }}
          onClick={(e) => activeDisplayRenfort(e, "Prestige", prestigeFinal)}
          className="renfort"
        >
          Renfort
        </button>
      </div>
      <ul className="plan-features">
        {prestige.map((element, id) => (
          
            <li key={"prestige_" + id} id={id + "_prestige"}>
              {" "}
              {id === 0 ? (
                <>
                  <p className="categoryMobile">Cotisation de base /an</p>
                </>
              ) : null}
              {id === 1 ? (
                <>
                  <p className="categoryMobile"> Réductions</p>
                </>
              ) : null}
              <span id={id + "_prestige_price"}>
                {id === 1 ? "-" : null}
                {element} €
              </span>
            </li>
          
        ))}
        <li style={{ height: "106px", borderBottom: "none" }} id="2_prestige">
          <span
            style={{
              fontSize: "2.6vw",
              color: "#509995",
              transform: "translate(0, 18%)",
            }}
            id="beforeRenfortPrestige"
            className="plan-price priceFinalMobile"
          >
            {prestigeFinal}€
          </span>
        </li>
      </ul>
      <div style={{ background: "#509995" }} className="plan-cost-final">
        <span>
          <input type="checkbox" id="checkPrestige"/>
          <label htmlFor="box-2">Choisir</label>
        </span>
      </div>
    </div>
  );
}

export default PrestigeTable;
