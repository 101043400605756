export function objectFromAray(arr) {
  if (arr === undefined)
    return []
  var newArrObj = []
  arr.map((element, id) => {
    newArrObj.push({
      'value': element,
      'label': element
    })
  })
  return newArrObj
}


export function subsetOfObject(object, arrOfKey) {
  var subset = {}
  for (const key of arrOfKey) {
    subset = { ...subset, [key]: object[key] };
  }
  return subset;
}

export function copyAllObjectExcept(object, arrOfKey) {
  var subset = {}
  var listOfObjectKeys = Object.keys(object)
  for (const key of listOfObjectKeys) {
    if (!arrOfKey.includes(key))
      subset = { ...subset, [key]: object[key] };
  }
  return subset
}