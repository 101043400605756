import * as Actions from "../actions";

const initialState = {
  city: [],
  inputValueChildren: [],
  inputValueConjoint: [],
  ext:  [
    {
      value: "00",
      label: "+33",
    },
    {
      value: "01",
      label: "+262",
    },
    {
      value: "03",
      label: "+269",
    },
    {
      value: "04",
      label: "+508",
    },
    {
      value: "05",
      label: "+590",
    },
    {
      value: "06",
      label: "+594",
    },
    {
      value: "07",
      label: "+594",
    },
    {
      value: "08",
      label: "+681",
    },
    {
      value: "09",
      label: "+687",
    },
    {
      value: "10",
      label: "+689",
    },
  ],
};

const envoiDevisReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_CITY: {
      return {
        ...state,
        city: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default envoiDevisReducer;
