import moment from "moment";

export function isValidDate(date, format) {
    let date_m = null
    if (date instanceof Date) {
        date_m = moment(date);
    }

    if (typeof date === 'string') {
        date_m = moment(date, format);
    }

    if (!date_m.isValid()) 
        return false;

    return true
}

export function formatDate(date){
    // date is a Date object
    date = moment(date)
    if (!date.isValid())
        return 'Invalid Date'
        
    return date.format("DD/MM/YYYY")

}

export function isMoreThanAge(birthdate, targetAge) {
    var birthdate_moment = moment(birthdate, "DD/MM/YYYY").startOf("year") 
    if (!birthdate_moment.isValid())
        return 'Invalid Date';

    let actualAge = moment().diff(birthdate_moment, 'years')
    console.log('actual age', actualAge)   
    if (actualAge >= targetAge)
        return true;
     
    return false;
  }

