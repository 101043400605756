import React, { useRef, useState, useEffect } from "react";
import * as Actions from "../Informations/store/actions";
import { useDispatch, useSelector } from "react-redux";
import Loading, { getRandomArbitrary } from "../../utils/Loading/Loading";
import BirthDayDate from "../../utils/dates/BirthDayDate";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { verifyAllInputs, verifyMaxChildren } from "../../utils/input/inputVerification.js";
import SelectComponent from "../../utils/input/SelectComponent.js";
import InputComponent from "../../utils/input/InputComponent.js"
import { subsetOfObject } from "../../utils/input/inputUtils"
import { SelectChoice, UnselectChoice } from "../../utils/input/ChoiceComponent"
import Grid from "@mui/material/Grid";
import Tooltip from "../../utils/other/Tooltip.js";
import ErrorIcon from '@mui/icons-material/Error';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// Input Verification Handling
let idLabel = ["insure_conjoint", "insure_children", "regime_conjoint", "dob_conjoint", "num_insured_children"];
let errorObject = {}
idLabel.forEach(element => {
  errorObject[element] = { initialState: true, error: false, helperText: "" }
})
errorObject.isAllGood = false;


function Famille(props) {
  var { data, regime, goToNextTarif, errorSendInformations } = useSelector(
    (state) => state.informationsReducer.informations
  );

  const [errorState, setErrorState] = useState(errorObject)
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(false);
  const [nonFormattedDate, setNonFormattedDate] = useState(null);

  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [randomGen] = useState(getRandomArbitrary(15, 25));
  const [activeLoading, setActiveLoading] = useState(false);

  const [dobValue, setValue] = useState(null);
  const [formInputs, setFormInputs] = useState({
    insure_children: "",
    insure_conjoint: "",
    regime_conjoint: "",
    dob_conjoint: "",
    num_insured_children: "",
    is_comm_offers: true
  });
  const conjointInfo = useRef();
  const childrenInfo = useRef();
  const conjointChoice = useRef();
  const childrenChoice = useRef();

  const insureConjointYesElem = document.getElementById("firstelemyes")
  const insureConjointNoElem = document.getElementById("firstelemno")
  const insureChildrenYesElem = document.getElementById("secondelemyes")
  const insureChildrenNoElem = document.getElementById("secondelemno")

  const maxInsureChildren = (data.children <= 2) ? data.children : 5

  var styleChoice = {
    left: "3%",
    display: "flex",
    whiteSpace: "nowrap",
  }


  useEffect(() => {
    if (activeLoading === true) {
      let timer = window.setTimeout(function () {
        const newProgress = progress + randomGen;
        if (newProgress < 100) {
          setProgress(newProgress);
        } else {
          setProgress(100);
          window.clearTimeout(timer);
        }
      }, 3000);
      return () => {
        window.clearTimeout(timer);
      };
    }
  }, [progress, activeLoading]);

  useEffect(() => {
    conjointInfo.current.style.display = "none";
    childrenInfo.current.style.display = "none";
  }, []);

  useEffect(() => {
    if (!goToNextTarif) {
      setErrorMessage(
        "Une erreur est survenue. Veuillez réessayer ultérieurement"
      );
      return;
    }
    dispatch(Actions.updateToNextPageTarif(false));
    props.goTo();

  }, [goToNextTarif]);


  useEffect(() => {

    // Créer une variable temporaire car sinon le state s'écrase
    var isDisplayInsureConjoint = displayInsureConjoint()
    conjointChoice.current.style.display = isDisplayInsureConjoint;

    var isDisplayInsureChildren = displayInsureChildren();
    childrenChoice.current.style.display = isDisplayInsureChildren;

  }, [data]);


  useEffect(() => {
    // Créer une variable temporaire car sinon le state s'écrase
    var isDisplayInfoConjoint = displayInfoConjoint()
    conjointInfo.current.style.display = isDisplayInfoConjoint;

    var isDisplayInfoChildren = displayInfoChildren();
    childrenInfo.current.style.display = isDisplayInfoChildren;

  }, [formInputs]);

  function handleClick(event) {
    event.preventDefault();
    var ignoredKeys = ["isAllGood"]
    setIsSubmitted(true);
    let dataToSend = {
    };


    if ([2, 3, 4].includes(data.fam_status)) {
      dataToSend = {
        ...dataToSend,
        insure_conjoint: formInputs.insure_conjoint,
      }
    }

    if (data.children) {
      dataToSend = {
        ...dataToSend,
        insure_children: formInputs.insure_children,
      }
    }

    if (formInputs.insure_conjoint === true) {
      dataToSend = {
        ...dataToSend,
        regime_conjoint: formInputs.regime_conjoint,
        dob_conjoint: formInputs.dob_conjoint,
      };

    }

    if (formInputs.insure_children === true) {
      dataToSend = {
        ...dataToSend,
        num_insured_children: parseInt(formInputs.num_insured_children),
      };

    }

    var errorStateVerif = subsetOfObject(errorObject, Object.keys(dataToSend))
    var verificationObject = verifyAllInputs(errorStateVerif, ignoredKeys)
    errorObject = {
      ...errorObject,
      ...verificationObject
    }

    setErrorState(errorObject)

    if (!errorObject.isAllGood) {
      return;
    }

    if (formInputs.insure_children === true) {
      var nc = dataToSend.num_insured_children
      var nc = (nc <= 3) ? nc : 3
      dataToSend = {
        ...dataToSend,
        num_insured_children: nc,
        actual_num_insured_children: parseInt(formInputs.num_insured_children)
      }
    }

    dataToSend = {
      ...data,
      ...dataToSend
    };

    dispatch(Actions.sendToBack(dataToSend));
    setActiveLoading(true);
    document.getElementById("loading").style.display = "block";
    document.getElementById("boxContent").style.opacity = "0.05";
    var button = document.getElementsByClassName("button-form")[0];
    button.disabled = "true"
    button.style.cursor = "progress"
    return
  }


  return (
    <>
      <Grid container item justifyContent={"center"}>
        <Loading error={errorMessage} progress={progress} />
      </Grid>

      <Grid container id="boxContent" >
        <div id="famille">
          <form >
            <Grid container item justifyContent={"center"} >
              <Grid item xs={11} sm={10}>
                <p className="main-form-title"  style={{height: "60px"}}>
                  <b style={{ fontWeight: "400", paddingLeft: "10px" }}>Votre famille</b>
                </p>
              </Grid>
            </Grid>

            <Grid container item spacing={{ xs: 3, sm: 6 }} justifyContent={"center"}>


              <Grid ref={conjointChoice} container item spacing={{ xs: 1, sm: 2 }}>

                <Grid container item justifyContent={"center"}>
                  <Grid item xs={10} sm={9}>
                    <p className="label-inputBesoin">
                      Je souhaite assurer mon conjoint
                    </p>
                  </Grid>
                </Grid>

                <Grid container item justifyContent={"center"} >
                  <Grid container item xs={10} sm={9} columnSpacing={1} zIndex={1}>
                    <Grid container item rowSpacing={1}>
                      <Grid item xs={4.5} sm={2.5} >
                        <a
                          onClick={() => {
                            activeFirstYes()
                          }}
                          style={{"minWidth": "50px"}}
                          id="firstelemyes"
                          className="buttonGeneral-devis"
                        >
                          Oui
                        </a>
                      </Grid>
                      <Grid item xs={4.5} sm={2.5} >
                        <a
                          onClick={() => {
                            activeFirstNo()
                          }}
                          style={{"minWidth": "50px"}}
                          id="firstelemno"
                          className="buttonGeneral-devis"
                        >
                          Non
                        </a>
                      </Grid>
                      <Grid container item   >
                        <Grid marginLeft={1} item xs={0.5} sm={0.25} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["insure_conjoint"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"insure_conjoint"} className="warning" style={getWarningStyle("insure_conjoint")}>
                            {errorState["insure_conjoint"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>



                <Grid ref={conjointInfo} container item justifyContent={"center"} spacing={3}>
                  <Grid container item justifyContent={"center"} spacing={1}>


                    <Grid container item xs={10} sm={9} rowSpacing={2}>
                      <Grid container item >
                        <Grid item xs={12} >
                          {SelectComponent(formInputs, setFormInputs,
                            regime, 'Regime du conjoint', 'regime_conjoint',
                            errorState['regime_conjoint'], isSubmitted, true)}
                        </Grid>
                      </Grid>
                      <Grid container item   >
                        <Grid marginLeft={1} item xs={0.5} sm={0.25} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["regime_conjoint"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"regime_conjoint"} className="warning" style={getWarningStyle("regime_conjoint")}>
                            {errorState["regime_conjoint"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid container item justifyContent={"center"} spacing={1}>

                    <Grid container item xs={10} sm={9} rowSpacing={2}>
                      <Grid container item >
                        <Grid item xs={12} >
                          {BirthDayDate(nonFormattedDate, setNonFormattedDate,
                            formInputs, setFormInputs,
                            "dob_conjoint",
                            errorState["dob_conjoint"], isSubmitted, true,
                            18, 68)}
                        </Grid>
                      </Grid>
                      <Grid container item   >
                        <Grid marginLeft={1} item xs={0.5} sm={0.25} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["dob_conjoint"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"dob_conjoint"} className="warning" style={getWarningStyle("dob_conjoint")}>
                            {errorState["dob_conjoint"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>



              <Grid ref={childrenChoice} container item spacing={{ xs: 1, sm: 2 }}>
                <Grid container item justifyContent={"center"}>
                  <Grid item xs={1} sm={0.5}>
                    {Tooltip(
                      openToolTip, setOpenToolTip,
                      "Assurance des enfants \nau delà du 3ème enfant est gratuite",
                      "#013f9c",
                      "bottom")}
                  </Grid>
                  <Grid item xs={9} sm={8.5}>
                    <p className="label-inputBesoin">
                      Je souhaite assurer mes enfants
                    </p>
                  </Grid>

                </Grid>

                <Grid container item justifyContent={"center"} >
                  <Grid container item xs={10} sm={9} columnSpacing={1} >
                    <Grid container item rowSpacing={1}>
                      <Grid item xs={4.5} sm={2.5} >
                        <a
                          id="secondelemyes"
                          onClick={() => {
                            activeSecondYes()
                          }}
                          style={{"minWidth": "50px"}}
                          className="buttonGeneral-devis"
                        >
                          Oui
                        </a>
                      </Grid>

                      <Grid item xs={4.5} sm={2.5} >
                        <a
                          onClick={() => {
                            activeSecondNo()
                          }}
                          style={{"minWidth": "50px"}}
                          id="secondelemno"
                          className="buttonGeneral-devis"
                        >
                          Non
                        </a>
                      </Grid>
                      <Grid container item   >
                        <Grid marginLeft={1} item xs={0.5} sm={0.25} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["insure_children"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"insure_children"} className="warning" style={getWarningStyle("insure_children")}>
                            {errorState["insure_children"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid ref={childrenInfo} container item justifyContent={"center"} spacing={3} sx={{ height: 140 }}>
                  <Grid container item justifyContent={"center"} spacing={1}>
                    <Grid item xs={10} sm={9}>
                      <p className="label-input">Nombre d'enfant à assurer</p>
                    </Grid>


                    <Grid container item xs={10} sm={9} rowSpacing={2}>
                      <Grid container item >
                        <Grid item xs={12} >
                          {InputComponent(formInputs, setFormInputs,
                            'max: ' + maxInsureChildren, 'num_insured_children', "1",
                            errorState["num_insured_children"], isSubmitted, true,
                            'numeric',
                            { pattern: '[0-9]', minRep: 1, maxRep: 1 }, verifyMaxChildren, data.children)}
                        </Grid>
                      </Grid>
                      <Grid container item xs={10} sm={5} >
                        <Grid item marginLeft={1} xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["num_insured_children"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item  >
                          <span className="warning" style={getWarningStyle("num_insured_children")}>
                            {errorState["num_insured_children"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item justifyContent={"center"}>
                <button style={{ marginTop: "30px" }} onClick={(e) => handleClick(e)} className="button-form">
                  Afficher les tarifs
                </button>
              </Grid>

            </Grid>

          </form>
        </div>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="snackFamille"
        open={errorSendInformations}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Une erreur est survenue. Veuillez réessayer.
        </Alert>
      </Snackbar>
    </>
  );

  function getWarningStyle(elementName) {
    var warningStyle = {
      visibility: isSubmitted && errorState[elementName].error ? 'visible' : 'hidden',
      height: 13,
      fontSize: '0.7rem',
    }
    return warningStyle;
  }

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    dispatch(Actions.updateMessageError(false));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };


  function activeFirstYes() {
    errorState.insure_conjoint.initialState = false
    errorState.insure_conjoint.error = false
    SelectChoice(insureConjointYesElem)
    UnselectChoice(insureConjointNoElem)
    setFormInputs({ ...formInputs, "insure_conjoint": true });
  };

  function activeFirstNo() {
    errorState.insure_conjoint.initialState = false
    errorState.insure_conjoint.error = false
    SelectChoice(insureConjointNoElem)
    UnselectChoice(insureConjointYesElem)
    setFormInputs({ ...formInputs, "insure_conjoint": false });
  };

  function activeSecondYes() {
    errorState.insure_children.initialState = false
    errorState.insure_children.error = false
    SelectChoice(insureChildrenYesElem)
    UnselectChoice(insureChildrenNoElem)
    setFormInputs({ ...formInputs, "insure_children": true });
  };

  function activeSecondNo() {
    errorState.insure_children.initialState = false
    errorState.insure_children.error = false
    SelectChoice(insureChildrenNoElem)
    UnselectChoice(insureChildrenYesElem)
    setFormInputs({ ...formInputs, "insure_children": false });
  };


  function displayInsureConjoint() {
    if ([1, 5, 6].includes(data.fam_status)) {
      return "none"
    }
    return "block"

  }

  function displayInfoConjoint() {
    if (formInputs.insure_conjoint)
      return "block"

    return "none"
  }


  function displayInsureChildren() {
    if (data.children)
      return "block"

    return "none"
  }


  function displayInfoChildren() {
    if (formInputs.insure_children)
      return "block"

    return "none"
  }

}

export default Famille;