import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import InfoIcon from '@mui/icons-material/Info';


function ToolTip(
  openToolTip,
  setOpenToolTip,
  title,
  color,
  placement,
) {
  function handleTooltipClose() {
    setOpenToolTip(false);
  }

  function handleTooltipOpen() {
    setOpenToolTip(true)
  }

  return (
    <div>
      <Tooltip
        onClose={handleTooltipClose}
        open={openToolTip}
        disableTouchListener
        title={
          <div style={{ whiteSpace: 'pre-line' }}>{title}</div>
        }
        placement={placement}
      >
        <InfoIcon
          onClick={handleTooltipOpen}
          sx={{ color: color, cursor: "pointer" }}
          fontSize={"medium"} />
      </Tooltip>
    </div >
  );
}

export default ToolTip;
