import React, { useState, useEffect } from "react";
import * as Actions from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import Loading, { getRandomArbitrary } from "../../utils/Loading/Loading";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { verifyTextInput, verifyAllInputs } from "../../utils/input/inputVerification.js";
import InputComponent from "../../utils/input/InputComponent.js"
import SelectComponent from "../../utils/input/SelectComponent.js";
import BirthDayDate from "../../utils/dates/BirthDayDate.js";
import { SelectChoice, UnselectChoice } from "../../utils/input/ChoiceComponent"
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ErrorIcon from '@mui/icons-material/Error';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Error
let idLabel = ["gender", "first_name", "last_name", "dob", "profession", "profession_type", "regime", "fam_status", "children", "email", "phone", "is_first_year"];
let errorObject = {}
let formInit = {}
idLabel.forEach(element => {
  errorObject[element] = { initialState: true, error: false, helperText: "" };
  formInit[element] = ""
})
errorObject.isAllGood = false;
const patternEmail = '[a-zA-Z0-9+_.@-]'
const patternValueEmail = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$'
var patternTextInput = '[a-zA-Z\u00C0-\u00FF- ]'
const patternDigit = '[0-9]'
const patterValuePhoneInput = '^0[1-9][0-9]{8}$'

function Formulaire(props) {
  var { profession,
    regime,
    situation,
    enfants,
    // data,
    goToNextTarif,
    errorSendInformations,
  } = useSelector((state) => state.informationsReducer.informations);


  const [errorState, setErrorState] = useState(errorObject)

  const [nonFormattedDate, setNonFormattedDate] = useState(null);
  const [activeLoading, setActiveLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [randomGen] = useState(getRandomArbitrary(15, 25));
  let errorMessage = "";
  const dispatch = useDispatch();
  const [formInputs, setFormInputs] = useState(formInit);

  const firstYearYesElem = document.getElementById("firstyes")
  const firstYearNoElem = document.getElementById("firstno")


  useEffect(() => {
    if (activeLoading === true) {
      let timer = window.setTimeout(function () {
        const newProgress = progress + randomGen;
        if (newProgress < 100) {
          setProgress(newProgress);
        } else {
          setProgress(100);
          window.clearTimeout(timer);
        }
      }, 3600);
      return () => {
        window.clearTimeout(timer);
      };
    }
  }, [progress, activeLoading]);

  useEffect(() => {
    var famDisplayType = displayFamilleDot()
    document.getElementById("famille").style.display = famDisplayType;
  }, [formInputs]);


  useEffect(() => {
    if (goToNextTarif === true) {
      dispatch(Actions.updateToNextPageTarif(false));
      errorMessage = "";
      props.goToTarif();
    }
  }, [goToNextTarif]);

  useEffect(() => {
    if (
      [2, 3, 4].includes(formInputs.fam_status) ||
      [1, 2, 3].includes(formInputs.children)
    ) {
      document.getElementById("famille").style.display = "block";
    } else {
      document.getElementById("famille").style.display = "none";
    }
  }, []);

  //sumbit the form
  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitted(true);

    var ignoredKeys = ["isAllGood"]
    errorObject = verifyAllInputs(errorState, ignoredKeys)
    setErrorState(errorObject)
    if (!errorObject.isAllGood) {
      return;
    }

    let phoneObj = { ext: "00", phone: formInputs.phone };
    var dataToSend = {
      ...formInputs,
      phone: phoneObj,
      is_comm_offers: true
    }

    if ([2, 3, 4].includes(formInputs.fam_status) || [1, 2, 3].includes(formInputs.children)) {
      dispatch(Actions.saveData(dataToSend));
      props.goTo();
      localStorage.setItem("famille", true);
      return;
    }

    window.gtag_report_conversion('AW-11268407841/TtiQCK6-tM0YEKGEmf0p')
    dispatch(Actions.sendToBack(dataToSend));
    localStorage.setItem("famille", false);
    setActiveLoading(true);
    document.getElementById("loading").style.display = "block";
    document.getElementById("boxContent").style.opacity = "0.08";
    var button = document.getElementsByClassName("button-form")[0];
    button.disabled = "true"
    button.style.cursor = "progress"
  }


  return (
    <>


      <form onSubmit={(e) => handleSubmit(e)}>
        <Grid container item justifyContent={"center"}>
          <Loading error={errorMessage} progress={progress} />
        </Grid>
        <Box id="boxContent" sx={{ flexGrow: 1 }}>

          <Grid container justifyContent={"center"} >

            <Grid container item justifyContent={"center"} >
              <Grid item xs={11} sm={10}>
                <p className="main-form-title" style={{ height: "20px" }}>
                  <b style={{ fontWeight: "400", paddingLeft: "10px" }}>Vos Informations</b>
                </p>
              </Grid>
            </Grid>

            {/* Grid container for all elenents of the form */}
            <Grid container item justifyContent={"center"} spacing={4}>

              {/* Grid container First and last name + gender and DOB */}
              <Grid container item spacing={{ xs: 1, sm: 1 }} justifyContent={"center"}>

                {/*First Last Name*/}
                <Grid container item spacing={1}>
                  <Grid container item justifyContent={"center"} columnSpacing={{ xs: 2, sm: 5 }}>
                    <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                      <Grid container item >
                        <Grid item xs={12} >
                          {InputComponent(formInputs, setFormInputs,
                            'Prénom', 'first_name', "",
                            errorState["first_name"], isSubmitted, true,
                            'text',
                            { pattern: patternTextInput, minRep: 1, maxRep: 30 }, verifyTextInput)}
                        </Grid>
                      </Grid>
                      <Grid container item   >
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["first_name"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"first_name"} className="warning" style={getWarningStyle("first_name")}>
                            {errorState["first_name"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                      <Grid container item >
                        <Grid item xs={12} >
                          {InputComponent(formInputs, setFormInputs,
                            'Nom', 'last_name', "",
                            errorState["last_name"], isSubmitted, true,
                            'text',
                            { "pattern": patternTextInput, "minRep": 1, "maxRep": 30 }, verifyTextInput)}
                        </Grid>
                      </Grid>
                      <Grid container item   >
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["last_name"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"last_name"} className="warning" style={getWarningStyle("last_name")}>
                            {errorState["last_name"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>


                {/* Gender + DOB*/}
                <Grid container item spacing={1}>

                  <Grid container item justifyContent={"center"} columnSpacing={{ xs: 4, sm: 5 }}>

                    <Grid item xs={5.5} sm={4.5} >
                      <p className="label-input" >Civilité</p>
                    </Grid>

                    <Grid item xs={5.5} sm={4.5}>
                    </Grid>

                  </Grid>

                  <Grid container item justifyContent={"center"} columnSpacing={{ xs: 2, sm: 5 }}>
                    <Grid container item justifyContent={"center"} xs={5.5} sm={4.5} style={{ height: "50px" }}>
                      <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                        <Grid container item columnSpacing={1}>
                          <Grid item xs={5.5} >
                            <a
                              style={{ marginLeft: "0" }}
                              onClick={() => activeMonsieur()}
                              id="monsieur"
                              className="buttonGeneral-devis"
                            >
                              M.
                            </a>
                          </Grid>
                          <Grid item xs={5.5} >
                            <a

                              onClick={() => activeMadame()}
                              id="madame"
                              className="buttonGeneral-devis"
                            >
                              Mme
                            </a>
                          </Grid>
                        </Grid>

                        <Grid container item   >
                          <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                            <ErrorIcon
                              sx={{
                                color: "#d32f2f", fontSize: 17,
                                visibility: (isSubmitted && errorState["gender"].error) ? "visible" : "hidden"
                              }}
                            >
                            </ErrorIcon>
                          </Grid>
                          <Grid item >
                            <span id={"gender"} className="warning" style={getWarningStyle("gender")}>
                              {errorState["gender"].helperText}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>

                    <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                      <Grid container item >
                        <Grid item xs={12} >
                          {BirthDayDate(nonFormattedDate, setNonFormattedDate, formInputs, setFormInputs,
                            'dob',
                            errorState['dob'], isSubmitted, true,
                            18, 68)}
                        </Grid>
                      </Grid>
                      <Grid container item   >
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["dob"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"dob"} className="warning" style={getWarningStyle("dob")}>
                            {errorState["dob"].helperText}
                          </span>
                        </Grid>


                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>


              {/* Grid container Email and phone + Activity and profession + installation and regime */}
              <Grid container item spacing={{ xs: 1, sm: 1 }} justifyContent={"center"}>

                {/* Email + Phone */}
                <Grid container item spacing={1}>
                  <Grid container item justifyContent={"center"} columnSpacing={{ xs: 2, sm: 5 }}>
                    {/* Email */}
                    <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                      <Grid container item xs={12}>
                        <Grid item xs={12} >
                          {InputComponent(
                            formInputs, setFormInputs,
                            "Email",
                            "email",
                            "@",
                            errorState["email"],
                            isSubmitted,
                            true,
                            "email",
                            { pattern: patternEmail, minRep: 1, maxRep: 30, valuePattern: patternValueEmail },
                            verifyTextInput
                          )}
                        </Grid>
                      </Grid>
                      <Grid container item   >
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["email"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span className="warning" style={getWarningStyle("email")}>
                            {errorState["email"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Phone */}
                    <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                      <Grid container item >
                        <Grid item xs={12} >
                          {InputComponent(
                            formInputs, setFormInputs,
                            "Téléphone",
                            "phone",
                            "0612345678",
                            errorState["phone"],
                            isSubmitted,
                            true,
                            'numeric', { pattern: patternDigit, minRep: 10, maxRep: 10, valuePattern: patterValuePhoneInput },
                            verifyTextInput
                          )}
                        </Grid>
                      </Grid>
                      <Grid container item   >
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: getWarningStyle("phone").visibility
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span className="warning" style={getWarningStyle("phone")}>
                            {errorState["phone"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>


                {/* Activity + Profession */}
                <Grid container item spacing={1}>
                  <Grid container item justifyContent={"center"} columnSpacing={{ xs: 4, sm: 5 }} >

                    <Grid item xs={5.5} sm={4.5} >
                      <p className="label-input" >Type d'activité</p>
                    </Grid>

                    <Grid item xs={5.5} sm={4.5} >

                    </Grid>
                  </Grid>

                  <Grid container item justifyContent={"center"} columnSpacing={{ xs: 2, sm: 5 }}>

                    <Grid container item justifyContent={"center"} xs={5.5} sm={4.5} >
                      <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                        <Grid container item columnSpacing={1}>
                          <Grid item xs={5.5} >
                            <a
                              onClick={() => activeLiberal()}
                              id="liberal"
                              className="buttonGeneral-devis"
                            >
                              Libéral
                            </a>
                          </Grid>
                          <Grid item xs={5.5} >
                            <a
                              onClick={() => activeSalarie()}
                              id="salarie"
                              className="buttonGeneral-devis"
                            >
                              Salarié
                            </a>
                          </Grid>
                        </Grid>

                        <Grid container item   >
                          <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                            <ErrorIcon
                              sx={{
                                color: "#d32f2f", fontSize: 17,
                                visibility: (isSubmitted && errorState["profession_type"].error) ? "visible" : "hidden"
                              }}
                            >
                            </ErrorIcon>
                          </Grid>
                          <Grid item >
                            <span id={"profession_type"} className="warning" style={getWarningStyle("profession_type")}>
                              {errorState["profession_type"].helperText}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>

                    <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                      <Grid container item >
                        <Grid item xs={12} >
                          {SelectComponent(formInputs, setFormInputs,
                            profession, 'Profession', 'profession',
                            errorState["profession"], isSubmitted, true)}
                        </Grid>
                      </Grid>
                      <Grid container item   >
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["profession"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"profession"} className="warning" style={getWarningStyle("profession")}>
                            {errorState["profession"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>


                {/* first year + Regime */}
                <Grid container item spacing={1}>
                  <Grid container item justifyContent={"center"} columnSpacing={{ xs: 0, sm: 5 }}>

                    <Grid item xs={5.5} sm={4.5} >
                      <p className="label-input" >1ère année d'installation</p>
                    </Grid>

                    <Grid item xs={5.5} sm={4.5} >

                    </Grid>
                  </Grid>

                  <Grid container item justifyContent={"center"} columnSpacing={{ xs: 2, sm: 5 }}>

                    <Grid container item justifyContent={"center"} xs={5.5} sm={4.5}>
                      <Grid container item rowSpacing={{ xs: 0.5, sm: 0.75 }}>
                        <Grid container item columnSpacing={1}>
                          <Grid item xs={5.5} >
                            <a
                              style={{ marginLeft: "0" }}
                              onClick={() => {
                                firstYearYes()
                              }}
                              id="firstyes"
                              className="buttonGeneral-devis"
                            >
                              Oui
                            </a>
                          </Grid>
                          <Grid item xs={5.5} >
                            <a
                              onClick={() => {
                                firstYearNo()
                              }}
                              id="firstno"
                              className="buttonGeneral-devis"
                            >
                              Non
                            </a>
                          </Grid>
                        </Grid>

                        <Grid container item   >
                          <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                            <ErrorIcon
                              sx={{
                                color: "#d32f2f", fontSize: 17,
                                visibility: (isSubmitted && errorState["is_first_year"].error) ? "visible" : "hidden"
                              }}
                            >
                            </ErrorIcon>
                          </Grid>
                          <Grid item >
                            <span id={"is_first_year"} className="warning" style={getWarningStyle("is_first_year")}>
                              {errorState["is_first_year"].helperText}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>

                    <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                      <Grid container item >
                        <Grid item xs={12} >
                          {SelectComponent(formInputs, setFormInputs,
                            regime, 'Regime', 'regime',
                            errorState["regime"], isSubmitted, true)}
                        </Grid>
                      </Grid>
                      <Grid container item   >
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["regime"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"regime"} className="warning" style={getWarningStyle("regime")}>
                            {errorState["regime"].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>


              {/* Situation + Nb Children */}
              <Grid container item spacing={1}>
                <Grid container item justifyContent={"center"} columnSpacing={{ xs: 2, sm: 5 }}>

                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item >
                      <Grid item xs={12} >
                        {SelectComponent(formInputs, setFormInputs,
                          situation, 'Situation familiale', 'fam_status',
                          errorState["fam_status"], isSubmitted, true)}
                      </Grid>
                    </Grid>
                    <Grid container item   >
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f", fontSize: 17,
                            visibility: (isSubmitted && errorState["fam_status"].error) ? "visible" : "hidden"
                          }}
                        >
                        </ErrorIcon>
                      </Grid>
                      <Grid item >
                        <span id={"fam_status"} className="warning" style={getWarningStyle("fam_status")}>
                          {errorState["fam_status"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={5.5} sm={4.5} rowSpacing={2}>
                    <Grid container item >
                      <Grid item xs={12} >
                        {SelectComponent(formInputs, setFormInputs,
                          enfants, "Nombre d'Enfant", 'children',
                          errorState["children"], isSubmitted, true)}
                      </Grid>
                    </Grid>
                    <Grid container item   >
                      <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                        <ErrorIcon
                          sx={{
                            color: "#d32f2f", fontSize: 17,
                            visibility: (isSubmitted && errorState["children"].error) ? "visible" : "hidden"
                          }}
                        >
                        </ErrorIcon>
                      </Grid>
                      <Grid item >
                        <span id={"children"} className="warning" style={getWarningStyle("children")}>
                          {errorState["children"].helperText}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>


                </Grid>
              </Grid>

              {/* Continue Button */}
              <Grid container item justifyContent={"center"}>
                <button id="continueInformation" className="button-form">
                  Continuer
                </button>
              </Grid>

            </Grid>

          </Grid>
        </Box>

      </form >

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="snackInformation"
        open={errorSendInformations}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Une erreur est survenue. Veuillez réessayer.
        </Alert>
      </Snackbar>
    </>
  );

  // 
  function getWarningStyle(elementName) {
    var warningStyle = {
      visibility: isSubmitted && errorState[elementName].error ? 'visible' : 'hidden',
      height: 13,
      fontSize: '0.7rem',
    }
    return warningStyle;
  }

  // Gestion particulière pour la date
  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    dispatch(Actions.updateMessageError(false));
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };


  function displayFamilleDot() {
    if ([2, 3, 4].includes(formInputs.fam_status) ||
      [1, 2, 3].includes(formInputs.children)) {
      return "block"
    }
    return "none"
  }

  function activeMonsieur() {
    errorState.gender.error = false;
    errorState.gender.initialState = false;
    const monsieurChoice = document.getElementById("monsieur");
    const madameChoice = document.getElementById("madame");
    SelectChoice(monsieurChoice)
    UnselectChoice(madameChoice)
    setFormInputs({ ...formInputs, "gender": "monsieur" });
  };

  function activeMadame() {
    errorState.gender.error = false;
    errorState.gender.initialState = false;
    const monsieurChoice = document.getElementById("monsieur");
    const madameChoice = document.getElementById("madame");
    SelectChoice(madameChoice);
    UnselectChoice(monsieurChoice);
    setFormInputs({ ...formInputs, "gender": "madame" });
  };

  function activeSalarie() {
    errorState.profession_type.error = false;
    errorState.profession_type.initialState = false;
    const liberalChoice = document.getElementById("liberal");
    const salarieChoice = document.getElementById("salarie");
    SelectChoice(salarieChoice);
    UnselectChoice(liberalChoice);
    setFormInputs({ ...formInputs, "profession_type": "salarie" });
  };

  function activeLiberal() {
    errorState.profession_type.error = false;
    errorState.profession_type.initialState = false;
    const liberalChoice = document.getElementById("liberal");
    const salarieChoice = document.getElementById("salarie");
    SelectChoice(liberalChoice);
    UnselectChoice(salarieChoice);
    setFormInputs({ ...formInputs, "profession_type": "liberal" });
  };

  function firstYearYes() {
    errorState.is_first_year.initialState = false
    errorState.is_first_year.error = false
    SelectChoice(firstYearYesElem)
    UnselectChoice(firstYearNoElem)
    setFormInputs({ ...formInputs, "is_first_year": true });
  };

  function firstYearNo() {
    errorState.is_first_year.initialState = false
    errorState.is_first_year.error = false
    SelectChoice(firstYearNoElem)
    UnselectChoice(firstYearYesElem)
    setFormInputs({ ...formInputs, "is_first_year": false });
  };

}



export default Formulaire;
