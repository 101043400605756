import { combineReducers } from 'redux';// Imports: Reducers
import informationsReducer from '../Component/Informations/store/reducer';
import tarifReducer from '../Component/Tarif/store/reducer';
import envoiDevisReducer from '../Component/EnvoiDevis/store/reducer';

// Redux: Root Reducer
const rootReducer = combineReducers({
  informationsReducer,
  tarifReducer,
  envoiDevisReducer
});
export default rootReducer;