import React, { useState, useEffect, useRef } from "react";
import * as Actions from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import typedeVoie from "./typevoie.json";
import PageFamily from "./PageFamily.js";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { verifyAllInputs, verifyTextInput } from "../../utils/input/inputVerification.js";
import InputComponent from "../../utils/input/InputComponent.js";
import SelectComponent from "../../utils/input/SelectComponent.js";
import AutoCompleteComponent from "../../utils/input/AutoCompleteComponent.js";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ErrorIcon from '@mui/icons-material/Error';
import { objectFromAray } from "../../utils/input/inputUtils"
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Error
let idLabel = ["num", "street_type", "street_name", "postal_code", "city", "consent"];
let errorObject = {};
let formInit = {};
idLabel.forEach((element) => {
  errorObject[element] = { initialState: true, error: false, helperText: "" };
  formInit[element] = ""
});
errorObject.isAllGood = false;

const patternTextInput = '[a-zA-Z\u00C0-\u00FF- ]'
const patternDigit = '[0-9]'


function EnvoiDevis(props) {

  var { dataBack } = useSelector(
    (state) => state.informationsReducer.informations
  );

  var { formules } = useSelector((state) => state.tarifReducer.tarif);
  var { tarif } = useSelector((state) => state.informationsReducer.informations);

  let infoToSendToBack = {
    gender: dataBack.gender,
    profession_type: dataBack.profession_type,
    children: dataBack.children,
    dob: dataBack.dob,
    fam_status: dataBack.fam_status,
    first_name: dataBack.first_name,
    last_name: dataBack.last_name,
    is_comm_offers: dataBack.is_comm_offers,
    is_first_year: dataBack.is_first_year,
    profession: dataBack.profession,
    regime: dataBack.regime,
    formules: [...formules],
  };
  const buttonName = formules.length > 1 ? "Obtenir mes devis" : "Obtenir mon devis"
  const [infoToSend, setInfoToSend] = useState(infoToSendToBack);

  const options = typedeVoie;
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmittedFamilyPage, setIsSubmittedFamilyPage] = useState(false);
  const [errorState, setErrorState] = useState(errorObject);

  const [isFamilyPage, setIsFamilyPage] = useState(false);
  const [isConsent, setIsConsent] = useState(false);
  const [openSuccessSnack, setOpenSuccessSnack] = React.useState(false);
  var { city } = useSelector(
    (state) => state.envoiDevisReducer.envoiDevis
  );

  const [formInputsUser, setFormInputsUser] = useState(formInit);

  const childrenRef = useRef();
  const conjointRef = useRef();


  function getWarningStyle(elementName) {
    var warningStyle = {
      visibility: isSubmitted && errorState[elementName].error ? 'visible' : 'hidden',
      height: 13,
      fontSize: '0.7rem',
    }
    return warningStyle;
  }


  useEffect(() => {
    if (formInputsUser.postal_code.length === 5) {
      dispatch(Actions.getCity(formInputsUser.postal_code));
    }
    setFormInputsUser({ ...formInputsUser, "city": "" })
    errorState["city"].initialState = true;
  }, [formInputsUser.postal_code])



  // handle submit function
  function handleSubmit(event) {
    setIsSubmitted(true);
    event.preventDefault();

    var ignoredKeys = ["isAllGood"]
    if ((dataBack.insure_conjoint || dataBack.insure_children)) {
      ignoredKeys.push('consent')
    }
    let verification = verifyAllInputs(errorState, ignoredKeys);
    setErrorState(verification)


    if (!verification.isAllGood) {
      return;
    }


    if (!isFamilyPage) {

      // Adress
      let addressObj = {
        num: parseInt(formInputsUser.num),
        street_type: formInputsUser.street_type,
        street_name: formInputsUser.street_name,
        postal_code: formInputsUser.postal_code,
        city: formInputsUser.city,
      };

      // Build object to send to backend
      infoToSendToBack = {
        ...infoToSend,
        phone: dataBack.phone,
        email: dataBack.email,
        address: addressObj,
      };
      setInfoToSend(infoToSendToBack)

      if (
        (dataBack.insure_conjoint || dataBack.insure_children)) {
        setIsFamilyPage(true);
        return;
      }

    }


    // verify family page info if family page exists
    if (isFamilyPage) {
      ignoredKeys = ["isAllGood"]
      let infoChildren = {};
      let infoConjoint = {};
      let childrenAllGood = true;
      let conjointAllGood = true;
      var errorStateToVerify = {}
      setIsSubmittedFamilyPage(true);

      if (typeof childrenRef.current !== "undefined") {
        infoChildren = childrenRef.current.getChildrenForm();
        errorStateToVerify = { ...infoChildren.errorState, consent: errorState.consent }
        var childrenVerification = verifyAllInputs(errorStateToVerify, ignoredKeys)
        infoChildren.setErrorFunction(childrenVerification)
        setErrorState({ ...errorState, consent: childrenVerification.consent })
        childrenAllGood = childrenVerification.isAllGood
      }

      if (typeof conjointRef.current !== "undefined") {
        infoConjoint = conjointRef.current.getConjointForm();
        errorStateToVerify = { ...infoConjoint.errorState, consent: errorState.consent }
        var conjointVerification = verifyAllInputs(errorStateToVerify, ignoredKeys)
        infoConjoint.setErrorFunction(conjointVerification)
        setErrorState({ ...errorState, consent: conjointVerification.consent })
        conjointAllGood = conjointVerification.isAllGood
      }

      if (!(childrenAllGood && conjointAllGood)) {
        return;
      }


      infoToSendToBack = {
        ...infoToSend
      }
      if (dataBack.insure_conjoint) {
        infoToSendToBack = {
          ...infoToSendToBack,
          insure_conjoint: dataBack.insure_conjoint,
          dob_conjoint: dataBack.dob_conjoint,
          regime_conjoint: dataBack.regime_conjoint,
          conjoint_info: infoConjoint.form
        };
        setInfoToSend(infoToSendToBack)
      }
      if (dataBack.insure_children) {
        infoToSendToBack = {
          ...infoToSendToBack,
          insure_children: dataBack.insure_children,
          num_insured_children: dataBack.num_insured_children,
          children_info: infoChildren.form
        };
        setInfoToSend(infoToSendToBack)
      }

    }

    infoToSendToBack = {
      form_info: infoToSendToBack,
      prices: tarif
    }
    setInfoToSend(infoToSendToBack)

    console.log(infoToSendToBack)
    window.gtag_report_conversion('AW-11268407841/5dfGCPWHv80YEKGEmf0p')
    document.getElementById("envoiButtonForm").disabled = true;
    dispatch(Actions.sendDevis(infoToSendToBack));
    setOpenSuccessSnack(true);

    // var is_stage = process.env.REACT_APP_STAGE || "false"
    // console.log(process.env.REACT_APP_GANALYTICS)
    // console.log(is_stage)


    return;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessSnack(false);
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  function stringProfessionSplit(profession) {
    var splits = profession.split(/\d{3} /);
    return splits[1];
  }

  return (
    <>

      <>
        <br />
        <div style={{ marginLeft: "10px" }} id="envoiDevis">
          <form

            onSubmit={(e) => handleSubmit(e)}
            id="envoiDevisForm">


            <Box
              sx={{ flexGrow: 1 }}>
              <Grid container  >
                <Grid container justifyContent={"center"} >
                  <Grid item xs={11} sm={10} >
                    <p className="main-form-title">
                      <b style={{ fontWeight: "400", paddingLeft: "10px" }}>Informations complémentaires</b>
                    </p>
                  </Grid>
                </Grid>
                {!isFamilyPage ? (
                  <>

                    <Grid container rowSpacing={{ xs: 0.5, sm: 3 }}>
                      <Grid container item>
                        <Grid container item spacing={0.5} justifyContent={"center"}  >
                          <Grid container item xs={10} sm={7} justifyContent={"center"} >
                            <Grid item xs={6} sm={6} >

                              <p className="label-input" >Prénom</p>
                              <input
                                disabled
                                value={dataBack.first_name}
                                className="input-form"
                                type="text"
                                name="first_name"
                                placeholder=""
                              />
                            </Grid>
                            <Grid item xs={6} sm={6} >
                              <div className="rowFormDevis">
                                <p className="label-input">Nom</p>
                                <input
                                  disabled
                                  pattern="^[A-Za-zÀ-ÿ ,.'-]+$"
                                  value={dataBack.last_name}
                                  type="text"
                                  name="last_name"
                                  className="input-form"
                                  placeholder=""
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0} justifyContent={"center"} >
                          <Grid container item xs={10} sm={7} justifyContent={"center"}>
                            <Grid item xs={6} sm={6} >
                              <div className="">
                                <p
                                  style={{ width: "100%" }}
                                  className="label-input"
                                >
                                  Date de naissance
                                </p>
                                <input
                                  disabled
                                  value={dataBack.dob}
                                  type="text"
                                  name="dob"
                                  className="input-form"
                                  placeholder=""
                                />
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <div className="rowFormDevis">
                                <p className="label-input">Profession</p>
                                <input
                                  disabled
                                  value={stringProfessionSplit(dataBack.profession)}
                                  type="text"
                                  name="profession"
                                  className="input-form"
                                  placeholder=""
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                      <Grid container item>
                        <Grid container item rowSpacing={{ xs: 2, sm: 3 }}>
                          <Grid container item justifyContent={"center"}>
                            <Grid item xs={10} sm={7} >
                              <p className="label-input">Adresse</p>
                            </Grid>
                          </Grid>

                          <Grid container item rowSpacing={{ xs: 4, sm: 5 }}>
                            <Grid container item rowSpacing={{ xs: 4, sm: 5 }} columnSpacing={{ xs: 1 }} justifyContent={"center"} >
                              <Grid item xs={3} sm={2} >
                                <div className="adressRow">
                                  {InputComponent(
                                    formInputsUser,
                                    setFormInputsUser,
                                    "N°",
                                    "num",
                                    "1",
                                    errorState["num"],
                                    isSubmitted,
                                    true,
                                    "number",
                                    { "pattern": patternDigit, "minRep": 1, maxRep: 3 },
                                    verifyTextInput
                                  )}
                                </div>

                              </Grid>
                              <Grid item xs={7} sm={5} >
                                <div>
                                  {AutoCompleteComponent(
                                    formInputsUser, setFormInputsUser,
                                    options,
                                    "Type de voie",
                                    "street_type",
                                    "Rue",
                                    errorState["street_type"],
                                    isSubmitted,
                                    true
                                  )}


                                </div>

                              </Grid>
                              <Grid item xs={10} sm={7} >
                                <div>
                                  {InputComponent(
                                    formInputsUser,
                                    setFormInputsUser,
                                    "Voie",
                                    "street_name",
                                    "de la république",
                                    errorState["street_name"],
                                    isSubmitted,
                                    true,
                                    'text',
                                    { "pattern": patternTextInput, "minRep": 1, maxRep: 40 },
                                    verifyTextInput
                                  )}
                                </div>

                              </Grid>
                            </Grid>

                            <Grid container item rowSpacing={2} justifyContent={"center"}  >
                              <Grid container item rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} justifyContent={"center"} >
                                <Grid item xs={4} sm={2} >
                                  {InputComponent(
                                    formInputsUser, setFormInputsUser,
                                    "Code postal",
                                    "postal_code",
                                    "13100",
                                    errorState["postal_code"],
                                    isSubmitted,
                                    true,
                                    'numeric', { pattern: patternDigit, minRep: 5, maxRep: 5 },
                                    verifyTextInput
                                  )}

                                </Grid>
                                <Grid item xs={6} sm={5} >
                                  <div>
                                    {SelectComponent(
                                      formInputsUser, setFormInputsUser,
                                      objectFromAray(city.cities),
                                      "Ville",
                                      "city",
                                      errorState["city"],
                                      isSubmitted,
                                      true
                                    )}

                                  </div>
                                </Grid>
                              </Grid>

                              <Grid container item xs={10} sm={7}>
                                <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                                  <ErrorIcon
                                    sx={{
                                      color: "#d32f2f", fontSize: 17,
                                      visibility: getWarningStyleFields(["num", "street_type", "street_name", "postal_code", "city"]).visibility
                                    }}
                                  >
                                  </ErrorIcon>
                                </Grid>
                                <Grid item >
                                  <span id="" className="warning" style={getWarningStyleFields(["num", "street_type", "street_name", "postal_code", "city"])}>
                                    {getHelperTextFromFields(["num", "street_type", "street_name", "postal_code", "city"])}
                                  </span>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>

                      {(!dataBack.insure_children && !dataBack.insure_conjoint) ? (

                        <Grid container item justifyContent={"center"}>
                          <Grid item xs={12} sm={7}>
                            <FormControlLabel labelPlacement="end" control={<Checkbox checked={isConsent} onChange={handleConsentChange} />} label={<span style={{ "fontSize": "13px", "textAlign": "justify", "display": "block" }}>En soumettant ce formulaire, j'accepte que les informations saisies soient traitées par <strong>CAREASSUR</strong> dans le cadre de ma demande. <a target="_blank" href="https://www.careassur.fr/politique-confidentialite.html" title="En savoir plus en consultant notre politique de confidentialité." style={{ "color": "#1C467E", "textDecoration": "none" }}>En savoir plus en consultant notre politique de confidentialité.</a>*</span>} />
                          </Grid>
                          <Grid container item xs={10} sm={7} >
                            <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                              <ErrorIcon
                                sx={{
                                  color: "#d32f2f", fontSize: 17,
                                  visibility: (isSubmitted && errorState["consent"].error) ? "visible" : "hidden"
                                }}
                              >
                              </ErrorIcon>
                            </Grid>
                            <Grid item >
                              <span id={"consent"} className="warning" style={getWarningStyle("consent")}>
                                Validation requise pour l'envoi du devis
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>


                      ) : null}
                    </Grid>

                  </>
                ) : (
                  <>
                    <PageFamily
                      formInputsUser={formInputsUser}
                      dataBack={dataBack}
                      conjointRef={conjointRef}
                      childrenRef={childrenRef}
                      isSubmittedFamilyPage={isSubmittedFamilyPage}
                      handleClose={handleClose}
                      handleSubmit={handleSubmit}
                      open={openSuccessSnack}
                    />
                    <Grid container item justifyContent={"center"} style={{ marginTop: "5px" }}>
                      <Grid item xs={12} sm={7}>
                        <FormControlLabel control={<Checkbox checked={isConsent} onChange={handleConsentChange} />} label={<span style={{ "fontSize": "11px", "textAlign": "justify", "display": "block" }}>En soumettant ce formulaire, j'accepte que les informations saisies soient traitées par <strong>CAREASSUR</strong> dans le cadre de ma demande. <a target="_blank" href="https://www.careassur.fr/politique-confidentialite.html" title="En savoir plus en consultant notre politique de confidentialité." style={{ "color": "#1C467E", "textDecoration": "none" }}>En savoir plus en consultant notre politique de confidentialité.</a>*</span>} />
                      </Grid>
                      <Grid container item xs={10} sm={7} >
                        <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: (isSubmitted && errorState["consent"].error) ? "visible" : "hidden"
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"consent"} className="warning" style={getWarningStyle("consent")}>
                            Validation requise pour l'envoi du devis
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </form>
          <button
            onClick={(e) => handleSubmit(e)}
            id="envoiButtonForm"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px"
            }}
            className="button-form button-submit"
          >
            {isFamilyPage || !(dataBack.insure_children || dataBack.insure_conjoint) ? buttonName : 'Suivant'}
          </button>
        </div>
        {/* CENTRER MSG */}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          id="snackEnvoi"
          open={openSuccessSnack}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Votre formulaire a bien été envoyé ! Vous serez redirigé vers la
            page principale du devis.
          </Alert>
        </Snackbar>
      </>
    </>
  );



  function getErrorFromFields(fieldNames) {
    for (const field of fieldNames) {
      if (errorState[field].error) {
        return true;
      }
    }
    return false;
  }

  function getHelperTextFromFields(fieldNames) {
    for (const field of fieldNames) {
      if (errorState[field].helperText !== "") {
        return errorState[field].helperText;
      }
    }
    return "";
  }

  function getWarningStyleFields(fieldNames) {
    var errorFields = getErrorFromFields(fieldNames)
    var warningStyle = {
      visibility: isSubmitted && errorFields ? 'visible' : 'hidden',
      fontSize: '0.7rem',
    }
    return warningStyle;
  }

  function handleConsentChange() {
    var consent = isConsent
    setIsConsent(!consent)
    setErrorState({ ...errorState, "consent": { error: consent, initialState: false } })
  }
}

export default EnvoiDevis;
