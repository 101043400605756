import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { MdKeyboardArrowRight } from "@react-icons/all-files/md/MdKeyboardArrowRight";
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, makeStyles } from '@mui/material/styles';

function ExampleRemboursement(props) {
    let { open, handleCloseRemboursement } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));

    return (
        <Dialog
        maxWidth={"xl"}
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseRemboursement}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Exemples de Remboursement"}
        </DialogTitle>
        <DialogContent>
            <div style={{margin: "50px"}}></div>
        <div className="snip1214">
          <div className="exempleRemboursement">
            <div className="cotisation">
              <span className="blocktext" style={{ color: "#EC0F6F" }}>
                Montures + 2 verres complexes
              </span>
              <MdKeyboardArrowRight className="arrowRight" />
            </div>
            <div className="cotisation">
              <span className="blocktext" style={{ color: "#EC0F6F" }}>
                Implants dentaires
              </span>
              <MdKeyboardArrowRight className="arrowRight" />
            </div>
            <div className="cotisation">
              <span className="blocktext">Chambre particulière</span>
              <MdKeyboardArrowRight className="arrowRight" />
            </div>
            <div className="cotisation">
              <span className="blocktext">Allocation de natalité</span>
              <MdKeyboardArrowRight className="arrowRight" />
            </div>
            <div className="cotisation">
              <span className="blocktext">Soins d'osthéopathie</span>
              <MdKeyboardArrowRight className="arrowRight" />
            </div>
          </div>
          <div className="boxTarif">
            <div className="plan">
              <h3 id="thirdTitle" className="plan-title">
                Economique
              </h3>
              <ul className="plan-features">
                <li>200.00 €</li>
                <li>-</li>
                <li>
                50.00€ / jour
                </li>
                <li>
                150.00 €
                </li>
                <li>-</li>
              </ul>
            </div>
            <div className="plan">
              <h3 id="fourTitle" className="plan-title">
                Confort
              </h3>
              <ul className="plan-features">
                <li>200.00 €</li>
                <li>-</li>
                <li>
                65.00€ / jour
                </li>
                <li>
                500.00 €
                </li>
                <li>-</li>
              </ul>
            </div>
            <div  className="plan">
              <h3 id="fiveTitle" className="plan-title">
                Prestige
              </h3>
              <ul className="plan-features">
                <li>470.00 €</li>
                <li>520.00 € (Renfort + 500.00€)</li>
                <li>
               Frais réels
                </li>
                <li>
                1500.00 €
                </li>
                <li>100.00 €</li>
              </ul>
            </div>
            <div  className="plan">
              <h3 id="firstTitle" className="plan-title">
                Premium
              </h3>
              <ul className="plan-features">
                <li>700.00 €</li>
                <li>700.00 € (Renfort + 500.00€)</li>
                <li>
               Frais réels
                </li>
                <li>
                1500.00 €
                </li>
                <li>200.00 €</li>
              </ul>
            </div>
          </div>
        </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseRemboursement}>
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    )
}

export default ExampleRemboursement;