import React, { useState, useEffect, useRef } from "react";
import Informations from "../Component/Informations/Informations";
import Famille from "../Component/Famille/Famille";
import Tarif from "../Component/Tarif/Tarif";
import EnvoiDevis from "../Component/EnvoiDevis/EnvoiDevis";

function Route() {
  const [changeComponent, setChangeComponent] = useState("informations");
  var famille = localStorage.getItem("famille");

  const goToInformations = () => {
    var informations = document.getElementById("informations");
    var famille = document.getElementById("famille");
    var tarif = document.getElementById("tarif");
    var envoie = document.getElementById("envoie");
    if (changeComponent !== "informations") {
      setChangeComponent("informations");
      informations.classList.add("is-active");
      famille.classList.remove("is-active");
      tarif.classList.remove("is-active");
      envoie.classList.remove("is-active");
    }
  };

  const goToFamille = () => {
    var informations = document.getElementById("informations");
    var famille = document.getElementById("famille");
    var tarif = document.getElementById("tarif");
    var envoie = document.getElementById("envoie");
    if (changeComponent !== "famille") {
      setChangeComponent("famille");
      informations.classList.remove("is-active");
      famille.classList.add("is-active");
      tarif.classList.remove("is-active");
      envoie.classList.remove("is-active");
    }
  };

  const goToTarif = () => {
    var informations = document.getElementById("informations");
    var famille = document.getElementById("famille");
    var tarif = document.getElementById("tarif");
    var envoie = document.getElementById("envoie");
    if (changeComponent !== "tarif") {
      setChangeComponent("tarif");
      informations.classList.remove("is-active");
      famille.classList.remove("is-active");
      tarif.classList.add("is-active");
      envoie.classList.remove("is-active");
    }
  };

  useEffect(() => {
    if (famille === "false") {
      document.getElementById("famille").style.display = "none";
    } else if (famille === "true") {
      document.getElementById("famille").style.display = "block";
    }
  }, [famille]);

  const goToEnvoi = () => {
    var informations = document.getElementById("informations");
    var famille = document.getElementById("famille");
    var tarif = document.getElementById("tarif");
    var envoie = document.getElementById("envoie");
    if (changeComponent !== "envoidevis") {
      setChangeComponent("envoidevis");
      informations.classList.remove("is-active");
      famille.classList.remove("is-active");
      tarif.classList.remove("is-active");
      envoie.classList.add("is-active");
    }
  };

  const Component = () => {
    switch (changeComponent) {
      case "informations":
        return <Informations goToTarif={goToTarif} goTo={goToFamille} />;
      case "famille":
        return <Famille reference={devisFormRef} goTo={goToTarif} />;
      case "tarif":
        return <Tarif goTo={goToEnvoi} />;
      case "envoidevis":
        return <EnvoiDevis />;
    }
  };

  useEffect(() => {
    <Component />;
  }, [changeComponent]);

 
  const devisFormRef = useRef(0);

  return (
    <>
      <div style={{display: "none"}} className="button-step">
        <span
          id="informations"
          className="is-active"
          onClick={goToInformations}
        ></span>
        <span id="famille"></span>
        <span id="tarif" className=""></span>
        <span id="envoie" className=""></span>
      </div>
      <div ref={devisFormRef} id="parent">
        <Component />
      </div>
      <a
        style={{ float: "left", display: "none" }}
        className="button-form"
        id="toInformations"
      >
        Retour
      </a>
    </>
  );
}

export default Route;
