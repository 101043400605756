import React, { useState, forwardRef, useEffect, useImperativeHandle } from "react";
import { verifyTextInput } from "../../utils/input/inputVerification.js";
import InputComponent from "../../utils/input/InputComponent";
import Grid from "@mui/material/Grid";
import BirthDayDate from "../../utils/dates/BirthDayDate.js"
import Tooltip from "../../utils/other/Tooltip.js";
import ErrorIcon from '@mui/icons-material/Error';

const ChildrenDevis = forwardRef((props, ref) => {

  let errorObject = {};
  for (const index of Array(props.dataBack.num_insured_children).keys()) {
    errorObject = {
      ...errorObject, ["first_name_" + index]: { initialState: true, error: false, helperText: "" },
      ["dob_" + index]: { initialState: true, error: false, helperText: "" }
    };

  };
  errorObject.isAllGood = true;


  const [inputValuesChildren, setInputValuesChildren] = useState({});
  const [openToolTip, setOpenToolTip] = useState(false);
  const [errorState, setErrorState] = useState(errorObject);
  const [nonFormattedDates, setNonFormattedDates] = useState({})

  function getWarningStyle(elementName) {
    var warningStyle = {
      visibility: props.isSubmittedFamilyPage && errorState[elementName].error ? 'visible' : 'hidden',

      fontSize: '0.7rem',
    }
    return warningStyle;
  }

  useEffect(() => {
    let formValues = {};
    let tempNonFormattedDate = [];
    let i = 0;
    while (i < props.dataBack.num_insured_children) {
      formValues[i] = {
        last_name: props.dataBack.last_name,
        first_name: "",
        dob: "",
      };
      tempNonFormattedDate[i] = null
      i += 1;
    }
    setInputValuesChildren(formValues);
    setNonFormattedDates(tempNonFormattedDate);
  }, [props.dataBack.num_insured_children]);

  var actual_num_insured_children = 4
  useImperativeHandle(ref, () => ({
    getChildrenForm() {
      // return Object.values(inputValuesChildren);
      return { "form": Object.values(inputValuesChildren), "errorState": errorState, "setErrorFunction": setErrorState };
    },
  }));

  var patternTextInput = "[a-zA-Z\u00C0-\u00FF- ]";
  return (
    <>
      {props.dataBack.actual_num_insured_children > 3 ?
        <Grid container item justifyContent={"center"} >

          <Grid item xs={2.75} sm={1.5} md={1} lg={0.75}>
            <p className="main-form-title form-subtitle">
              <b style={{ fontWeight: "400", paddingLeft: "10px" }}>Enfants</b>
            </p>
          </Grid>
          <Grid item xs={7.25} sm={8} md={8.5} lg={8.75}>
            {Tooltip(
              openToolTip,
              setOpenToolTip,
              "Vous avez déclaré vouloir assurer " + actual_num_insured_children +
              " enfants, vous pouvez en déclarer que 3 et nous fournir le reste des informations lors de la conclusion du contrat",
              '#013f9c',
              "right-start"
            )}
          </Grid>
        </Grid>
        : <Grid container item justifyContent={"center"} >
          <Grid item xs={11} sm={9.5}>
            <p className="main-form-title form-subtitle">
              <b style={{ fontWeight: "400", paddingLeft: "10px" }}>Enfants</b>
            </p>
          </Grid>
        </Grid>}
      {props.dataBack.insure_children &&
        [...Array(props.dataBack.num_insured_children)].map((value, index) => {
          var displayIndex = index + 1
          return (
            (inputValuesChildren[index]) &&
            <Grid key={"container_" + index} container  style={{"marginTop": "-8px"}}>
              <Grid container item rowSpacing={1} justifyContent={"center"}>
                <Grid container item justifyContent={"center"} xs={11} sm={9}>
                  <Grid container item >
                    <Grid item xs={6} sm={5.5} >
                      <p className="label-input">{"Enfant " + displayIndex}</p>
                    </Grid>


                  </Grid>
                </Grid>


                <Grid container item justifyContent={"center"} xs={11} sm={9}>
                  <Grid container item columnSpacing={{ xs: 2, sm: 5 }}>


                    <Grid container item xs={6} sm={5.5} rowSpacing={2}>
                      <Grid container item >
                        <Grid item xs={12} >
                          {InputComponent(
                            inputValuesChildren, setInputValuesChildren,
                            "Prénom ", "first_name", "",
                            errorState["first_name_" + index], props.isSubmittedFamilyPage, true,
                            'text',
                            { "pattern": patternTextInput, "minRep": 1, "maxRep": 30 },
                            verifyTextInput,
                            null,
                            index
                          )}
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid marginLeft={1} item xs={0.85} sm={0.65} lg={0.35}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: getWarningStyle("first_name_" + index).visibility
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"first_name_" + index} className="warning" style={getWarningStyle("first_name_" + index)}>
                            {errorState["first_name_" + index].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item xs={6} sm={5.5} rowSpacing={2}>
                      <Grid container item >
                        <Grid item xs={12} >
                          {BirthDayDate(
                            nonFormattedDates, setNonFormattedDates,
                            inputValuesChildren, setInputValuesChildren, "dob",
                            errorState["dob_" + index], props.isSubmittedFamilyPage, true, 0, 31, index)}
                        </Grid>
                      </Grid>
                      <Grid container item>
                        <Grid marginLeft={1} item xs={0.85} sm={0.65} lg={0.35}>
                          <ErrorIcon
                            sx={{
                              color: "#d32f2f", fontSize: 17,
                              visibility: getWarningStyle("dob_" + index).visibility
                            }}
                          >
                          </ErrorIcon>
                        </Grid>
                        <Grid item >
                          <span id={"dob_" + index} className="warning" style={getWarningStyle("dob_" + index)}>
                            {errorState["dob_" + index].helperText}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>

              </Grid>
            </Grid>
          );
        })}
    </>
  );
});

export default ChildrenDevis;
