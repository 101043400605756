function PremiumTable(
  boxPremium,
  onClickbox,
  activeDisplayRenfort,
  premium,
  premiumFinal
) {
  return (
    <div
      ref={boxPremium}
      id="planPremium"
      // className="planTarif"
      style={{ borderTop: "10px solid #67B9A4" }}
      className="plan"
      onClick={() => onClickbox("Premium", premiumFinal)}
      // onTouchStart={onClickboxPremium}
    >
      <div className="plan-cost">
        <h2>Premium</h2>
        <button
          id="buttonRenfortPremium"
          onClick={(e) => activeDisplayRenfort(e, "Premium", premiumFinal)}
          // style={{ background: "#1C467E" }}
          className="renfort"
        >
          Renfort
        </button>
      </div>
      <ul className="plan-features">
        {premium.map((element, id) => (
          
            <li key={"premium_" + id} id={id + "_premium"}>
              {" "}
              {id === 0 ? (
                <>
                  <p className="categoryMobile">Cotisation de base /an</p>
                </>
              ) : null}
              {id === 1 ? (
                <>
                  <p className="categoryMobile"> Réductions</p>
                </>
              ) : null}
              <span id={id + "_premium_price"}>
                {id === 1 ? "-" : null}
                {element} €
              </span>{" "}
            </li>
          
        ))}
        <li style={{ height: "106px", borderBottom: "none" }} id="2_premium">
          <span
            style={{
              fontSize: "2.6vw",
              color: "#67B9A4",
              transform: "translate(0, 18%)",
            }}
            id="beforeRenfortPremium"
            className="plan-price priceFinalMobile"
          >
            {premiumFinal}€
          </span>
        </li>
      </ul>
      <div style={{ background: "#67B9A4" }} className="plan-cost-final">
        <span>
        <input type="checkbox" id="checkPremium"/>
          <label htmlFor="box-2">Choisir</label>
        </span>
      </div>
    </div>
  );
}

export default PremiumTable;
