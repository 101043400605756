import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import "moment/locale/sw";
import moment from "moment";
import locale from "date-fns/locale/fr";
import FormControl from "@mui/material/FormControl";
import { verifyDob } from "../../utils/input/inputVerification"
import { formatDate } from "../../utils/dates/check";

function ChangeInput(value, nonFormattedDates, setNonFormattedDates, formInputs, setFormInputs, name, index) {

  var tempInputs = { ...formInputs };
  var tempNonFormattedDates = { ...nonFormattedDates }
  if (index === null) {
    tempInputs[name] = formatDate(value, "dd/mm/yyyy");
    tempNonFormattedDates = value
    setFormInputs(tempInputs)
    setNonFormattedDates(tempNonFormattedDates)
    return
  }
  tempInputs[index][name] = formatDate(value, "dd/mm/yyyy");
  tempNonFormattedDates[index] = value
  setFormInputs(tempInputs)
  setNonFormattedDates(tempNonFormattedDates)
}


function BirthDayDate(nonFormattedDates, setNonFormattedDates, formInputs, setFormInputs,
  name,
  elementErrorState, isSubmitted, disableHelperText,
  minAge, maxAge, index = null) {

  const inputStyle = {
    border: 0,
    borderRadius: 3,
    color: "white",
    height: 30,
    '& .MuiOutlinedInput-root':
    {
      '& fieldset': {
        borderColor: isSubmitted && elementErrorState.error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
      },
    }
  }

  return (
    <div className="">
      <FormControl className="formControlCSS" fullWidth sx={inputStyle}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
          <DesktopDatePicker

            // cancelText="Annuler"
            name={name}
            label="Date de naissance"
            id={name}
            minDate={
              new Date(moment().subtract(maxAge - 1, "year").startOf("year").format("YYYY-MM-DD"))
            }
            maxDate={
              new Date(moment().subtract(minAge, "year").format("YYYY-MM-DD"))
            }
            // views={["year", "month", "day"]}
            // openTo={"year"}
            // disableCloseOnSelect={false}
            value={(index === null) ? nonFormattedDates : nonFormattedDates[index]}
            allowSameDateSelection={true}
            onChange={(value) => {
              ChangeInput(value, nonFormattedDates, setNonFormattedDates, formInputs, setFormInputs, name, index)
              var inputVerification = verifyDob(value, minAge, maxAge)
              elementErrorState.initialState = false;
              elementErrorState.error = !inputVerification.isValid
              elementErrorState.helperText = inputVerification.helperText
            }
            }
            inputFormat="dd/MM/yyyy"
            disableOpenPicker
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true, style: { fontSize: 15 } }}
                inputProps={{ ...params.inputProps, placeholder: "JJ/MM/AAAA" }}

                helperText={(!disableHelperText && isSubmitted && elementErrorState.error) ? elementErrorState.helperText : ""}
                size="small"
                id={(index != null) ? "dob_0" : 'dob_' + index}

              />
            )}
          />
        </LocalizationProvider>
      </FormControl>
    </div>

  );
}
export default BirthDayDate;
