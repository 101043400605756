import React, { useState, forwardRef, useImperativeHandle } from "react";
import InputComponent from "../../utils/input/InputComponent";
import Grid from "@mui/material/Grid";
import { verifyTextInput } from "../../utils/input/inputVerification.js";
import { SelectChoice, UnselectChoice } from "../../utils/input/ChoiceComponent"
import ErrorIcon from '@mui/icons-material/Error';

let idLabel = ["first_name", "last_name", "gender"];
let errorObject = {};
idLabel.forEach((element) => {
  errorObject[element] = { initialState: true, error: false, helperText: "" };
});
errorObject.isAllGood = true;

// regex verification
var patternTextInput = '[a-zA-Z\u00C0-\u00FF- ]'

const ConjointDevis = forwardRef((props, ref) => {

  const [errorState, setErrorState] = useState(errorObject);
  const [inputValuesConjoint, setInputValuesConjoint] = useState({
    gender: "",
    last_name: "",
    first_name: "",
  });


  function getWarningStyle(elementName) {
    var warningStyle = {
      visibility: props.isSubmittedFamilyPage && errorState[elementName].error ? 'visible' : 'hidden',
      height: 13,
      fontSize: '0.7rem',
    }
    return warningStyle;
  }

  useImperativeHandle(ref, () => ({
    getConjointForm() {
      return { "form": inputValuesConjoint, "errorState": errorState, "setErrorFunction": setErrorState };
    },
  }));

  const activeMonsieur = () => {
    const monsieurConjoint = document.getElementById("monsieurConjoint")
    const madameConjoint = document.getElementById("madameConjoint")
    errorState["gender"].error = false;
    errorState["gender"].initialState = false;
    SelectChoice(monsieurConjoint);
    UnselectChoice(madameConjoint);
    setInputValuesConjoint({ ...inputValuesConjoint, ["gender"]: "monsieurConjoint" });
  };

  const activeMadame = () => {
    const monsieurConjoint = document.getElementById("monsieurConjoint")
    const madameConjoint = document.getElementById("madameConjoint")
    errorState["gender"].error = false;
    errorState["gender"].initialState = false;
    SelectChoice(madameConjoint);
    UnselectChoice(monsieurConjoint);
    setInputValuesConjoint({ ...inputValuesConjoint, ["gender"]: "madameConjoint" });
  };

  return (
    props.dataBack.insure_conjoint && (
      <>
        <Grid container item justifyContent={"center"} >
          <Grid item xs={11} sm={9.5} >
            <p className="main-form-title form-subtitle">
              <b style={{ fontWeight: "400", paddingLeft: "10px" }}>Conjoint</b>
            </p>
          </Grid>
        </Grid>

        <Grid id="conjoint" container spacing={1} justifyContent={"center"} >
          <Grid container id="gender_conjoint" item xs={11} sm={9}  >
            <Grid item>
              <p className="label-input" >Civilité</p>
            </Grid>
          </Grid>

          <Grid container item xs={11} sm={9} rowSpacing={1}>
            <Grid container item >
              <Grid item xs={5} sm={3} >
                <a
                  style={{ margin: "0px", zIndex: "100" }}
                  onClick={() => activeMonsieur()}
                  id="monsieurConjoint"
                  className="buttonGeneral-devis"
                // style={{marginLeft: "-65px"}}
                >
                  M.
                </a>
              </Grid>
              <Grid item xs={5} sm={3} >
                <a
                  style={{ zIndex: "100" }}
                  onClick={() => activeMadame()}
                  id="madameConjoint"
                  className="buttonGeneral-devis"
                >
                  Mme
                </a>
              </Grid>
            </Grid>
            <Grid container item xs={11} sm={7}>
              <Grid marginLeft={1} item xs={0.75} sm={0.5} lg={0.25}>
                <ErrorIcon
                  sx={{
                    color: "#d32f2f", fontSize: 17,
                    visibility: getWarningStyle("gender").visibility
                  }}
                >
                </ErrorIcon>
              </Grid>
              <Grid item >
                <span id={"gender"} className="warning" style={getWarningStyle("gender")}>
                  {errorState["gender"].helperText}
                </span>
              </Grid>
            </Grid>

          </Grid>


          <Grid container item justifyContent={"center"} xs={11} sm={9}>
            <Grid container item columnSpacing={{ xs: 2, sm: 5 }}>

              <Grid container item xs={6} sm={5.5} rowSpacing={2}>
                <Grid container item >
                  <Grid item xs={12} >
                    {InputComponent(
                      inputValuesConjoint, setInputValuesConjoint,
                      "Prénom", "first_name", "",
                      errorState["first_name"], props.isSubmittedFamilyPage, true,
                      'text',
                      { "pattern": patternTextInput, "minRep": 1, "maxRep": 30 }, verifyTextInput
                    )}
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid marginLeft={1} item xs={0.85} sm={0.65} lg={0.35}>
                    <ErrorIcon
                      sx={{
                        color: "#d32f2f", fontSize: 17,
                        visibility: getWarningStyle("first_name").visibility
                      }}
                    >
                    </ErrorIcon>
                  </Grid>
                  <Grid item >
                    <span id={"first_name"} className="warning" style={getWarningStyle("first_name")}>
                      {errorState["first_name"].helperText}
                    </span>
                  </Grid>
                </Grid>
              </Grid>


              <Grid container item xs={6} sm={5.5} rowSpacing={2}>
                <Grid container item >
                  <Grid item xs={12} >
                    {InputComponent(
                      inputValuesConjoint, setInputValuesConjoint,
                      "Nom", "last_name", "",
                      errorState["last_name"], props.isSubmittedFamilyPage, true,
                      'text',
                      { "pattern": patternTextInput, "minRep": 1, "maxRep": 30 }, verifyTextInput
                    )}
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid marginLeft={1} item xs={0.85} sm={0.65} lg={0.35}>
                    <ErrorIcon
                      sx={{
                        color: "#d32f2f", fontSize: 17,
                        visibility: getWarningStyle("last_name").visibility
                      }}
                    >
                    </ErrorIcon>
                  </Grid>
                  <Grid item >
                    <span id={"last_name"} className="warning" style={getWarningStyle("last_name")}>
                      {errorState["last_name"].helperText}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

      </>

    )
  );
});

export default ConjointDevis;
