import * as React from 'react';
import LogoCareassur from "../../public/logo.png"
import CircularProgress, {
  circularProgressClasses
} from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import BouncingDotsLoader from "./BouncingDots.js"

export function getRandomArbitrary(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

function Loading(props) {

  return (
    // <div id="loading" className="loading">
    // {/* <img style={{ width: "50%" }} src={LogoCareassur} /> */}
    // <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    //   <CircularProgress variant="determinate" {...props} />
    //   <Box
    //     sx={{
    //       top: 0,
    //       left: 0,
    //       bottom: 0,
    //       right: 0,
    //       position: 'absolute',
    //       display: 'flex',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //     }}
    //   >
    //     <Typography
    //       variant="caption"
    //       component="div"
    //       color="text.secondary"
    //     >{props.progress}</Typography>
    //   </Box>
    // </Box>
    // 
    <div style={{ top: "20%", position: "fixed", zIndex: 2 }} id="loading" className="loading" >


      <Grid container item justifyContent={"center"} rowSpacing={{ xs: 3, sm: 4 }}>
        <Grid container item justifyContent={"center"}>
          <img style={{ width: "40vw" }} src={LogoCareassur} />
        </Grid>
        <Grid container item justifyContent={"center"} columnSpacing={1}>
          <Grid item >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
              fontSize="4vw"
            >Nous calculons votre tarif </Typography>
          </Grid>
          <Grid item  >
            <BouncingDotsLoader />
          </Grid>
        </Grid>
        <Grid container item justifyContent={"center"}>
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <Box sx={{ position: 'relative' }}>
              <CircularProgress
                variant="determinate"
                sx={{
                  color: (theme) =>
                    theme.palette.grey[theme.palette.mode === "light" ? 200 : 800]
                }}
                size="30vw"
                thickness={2}
                {...props}
                value={100}
              />
              <CircularProgress
                variant="determinate"
                value={props.progress}
                size="30vw"
                thickness={2}
                sx={{
                  position: "absolute",
                  left: 0,
                  color: "#1C467E",
                  animationDuration: "550ms",
                  // position: "absolute",
                  // left: 0,
                  [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: "round"
                  }
                }}
              ></CircularProgress>
            </Box>
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                fontSize="5vw"
                sx={{ visibility: (props.progress > 0) ? "visible" : "hidden" }}
              >{props.progress}%</Typography>
            </Box>
          </Box>
        </Grid>

      </Grid>

      {/* // <div><p style={{ textAlign: "right" }}>{props.progress}%</p></div> */}
    </div>
  )
}

export default Loading;