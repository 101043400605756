import React, { useEffect } from "react";
import "./App.css";
import "./CSS/Form.css";
import "./CSS/Tarif.css";
import "./CSS/Loading.css";
import "./CSS/Famille.css";
import "./CSS/EnvoiDevis.css";
import "./CSS/Responsive.css";
import "./CSS/muiCSS.css";
import "./CSS/Button.css";
import "./CSS/BouncingDots.css";

import Route from "./Route/route";
import { store } from './store/configStore.js';
import { Provider } from "react-redux";

function App() {
  return (
    <Provider store={store}>    
    <div  className="devis-form">
      <div className="devis-complementaire">
        <Route/>
      </div>
      <br />
    </div>
    </Provider>
  );
}

export default App;
