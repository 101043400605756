import * as Actions from "../actions";

const initialState = {
  formules: [],
};

const tarifReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_FORMULES: {
      return {
        ...state,
        formules: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default tarifReducer;
