import axios from "axios";

export const GET_CITY = "[ENVOI DEVIS] GET CITY";
export const SEND_DEVIS = "[ENVOI DEVIS] SEND DEVIS"

const url = process.env.REACT_APP_BACKEND_API_URL;
console.log(url)
console.log(process.env.REACT_APP_BACKEND_API_URL)
let axiosConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

export const getCity = (payload) => {
  const result = axios.get(
    url + "/quotes/cities?code=" + payload ,
    axiosConfig
  );
  return (dispatch) => {
    result.then((response) => {
      dispatch({
        type: GET_CITY,
        payload: response.data,
      });
    });
  };
};


export const sendDevis = (payload) => {
  const result = axios.post(url + "/quotes/send_quote" , payload, axiosConfig);
  return (dispatch) => {
    result.then((response) => {
      dispatch({
        type: SEND_DEVIS,
        payload: response.data
      })
    })
  }
}
