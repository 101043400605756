import axios from "axios";

export const SAVE_DATA = "[INFORMATIONS] SAVE DATA";
export const SEND_BACK = "[INFORMATIONS] SEND BACK";
export const UPDATE_TO_NEXT_PAGE_TARIF = "[INFORMATIONS] UPDATE TO NEXT PAGE TARIF";
export const ERROR_BACK = "[INFORMATIONS] ERROR BACK";
export const UPDATE_MESSAGE_ERROR = "[INFORMATIONS] UPDATE MESSAGE ERROR";

const url = process.env.REACT_APP_BACKEND_API_URL;
console.log(url)
let axiosConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

export const saveData = (payload) => {
  return {
    type: SAVE_DATA,
    payload: payload,
  };
};

export const sendToBack = (payload, setUpdateProgress) => {
  const result = axios.post(url + "/quotes/quote", payload, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  //   onUploadProgress: (progressEvent) => {
  //     let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  //     setUpdateProgress(percentCompleted);
  //   },
  });
  return (dispatch) => {
    result.then((response) => {
      dispatch({
        type: SEND_BACK,
        payload: response.data,
        data: payload,
        goToNextTarif: true,
      });
    })
    .catch((error) => {
      dispatch({
        type: ERROR_BACK,
        data: error,
        goToNextTarif: false,
      });
    });
  };
};

export const updateToNextPageTarif = (payload) => {
  return {
    type: UPDATE_TO_NEXT_PAGE_TARIF,
    payload: payload,
  };
};

export const updateMessageError = (payload) => {
  return {
    type: UPDATE_MESSAGE_ERROR,
    payload: payload
  }
}