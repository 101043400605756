import React, { useEffect, useRef, useState } from "react";
import ExampleRemboursement from "./ExampleRemboursement";
import Exemple from "../../public/Document/examples.pdf";
import Tooltip from "../../utils/other/Tooltip.js";
import { useDispatch, useSelector } from "react-redux";
import EconomicTable from "./EconomicTable.js";
import ConfortTable from "./ConfortTable.js";
import PrestigeTable from "./PrestigeTable.js";
import PremiumTable from "./PremiumTable.js";
import * as Actions from "./store/actions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { green, blue, cyan } from "@mui/material/colors";
import Grid from "@mui/material/Grid";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Tarif(props) {
  var { tarif } = useSelector((state) => state.informationsReducer.informations);
  // Test Data
  // let tarif = {
  //   full_price: {
  //     eco: 928.82,
  //     confort: 1225.73,
  //     prestige: 1815.71,
  //     premium: 2524.22,
  //     renfort: 400.32,
  //   },
  //   under_fifty_one_discount: {
  //     eco: 278.65,
  //     confort: 367.72,
  //     prestige: 544.71,
  //     premium: 757.27,
  //   },
  //   final_price: {
  //     eco: 650.17,
  //     confort: 858.01,
  //     prestige: 1271,
  //     premium: 1766.95,
  //     renfort: 280.22,
  //   },
  // };

  const dispatch = useDispatch();

  // Price with reduc
  const [eco, setEco] = useState([]);
  const [confort, setConfort] = useState([]);
  const [prestige, setPrestige] = useState([]);
  const [premium, setPremium] = useState([]);
  const [renfort, setRenfort] = useState([]);

  // Final Price
  const [ecoFinal, setEcoFinal] = useState("");
  const [confortFinal, setConfortFinal] = useState("");
  const [prestigeFinal, setPrestigeFinal] = useState("");
  const [premiumFinal, setPremiumFinal] = useState("");
  const [renfortFinal, setRenfortFinal] = useState([]);

  const boxPremium = useRef(null);
  const boxEconomique = useRef(null);
  const boxConfort = useRef(null);
  const boxPrestige = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const [openToolTip1, setOpenToolTip1] = React.useState(false);
  const [openToolTip2, setOpenToolTip2] = React.useState(false);


  const handleClose = () => {
    setOpenSnack(false);
  };


  useEffect(() => {
    if (tarif.lenght !== 0 && tarif.final_price) {
      let ecoTemp = [];
      let confortTemp = [];
      let prestigeTemp = [];
      let premiumTemp = [];
      let renfortTemp = [];
      // Il a moins de 51 ans
      if (
        (tarif?.under_fifty_one_discount !== null ||
          typeof tarif.under_fifty_one_discount !== "undefined" ||
          tarif?.under_fifty_one_discount) &&
        tarif?.under_fifty_one_discount
      ) {
        ecoTemp = [...ecoTemp, tarif.full_price.eco];
        confortTemp = [...confortTemp, tarif.full_price.confort];
        prestigeTemp = [...prestigeTemp, tarif.full_price.prestige];
        premiumTemp = [...premiumTemp, tarif.full_price.premium];
        renfortTemp = [...renfortTemp, tarif.full_price.renfort];

        if (
          tarif.final_price !== null ||
          typeof tarif.final_price !== "undefined"
        ) {
          setEcoFinal(tarif.final_price.eco);
          setConfortFinal(tarif.final_price.confort);
          setPrestigeFinal(tarif.final_price.prestige);
          setPremiumFinal(tarif.final_price.premium);
          setRenfortFinal(tarif.final_price.renfort);
        }

        ecoTemp = [...ecoTemp, tarif.under_fifty_one_discount.eco];
        confortTemp = [...confortTemp, tarif.under_fifty_one_discount.confort];
        prestigeTemp = [
          ...prestigeTemp,
          tarif.under_fifty_one_discount.prestige,
        ];
        premiumTemp = [...premiumTemp, tarif.under_fifty_one_discount.premium];
      } else {
        setEcoFinal(tarif.final_price.eco);
        setConfortFinal(tarif.final_price.confort);
        setPrestigeFinal(tarif.final_price.prestige);
        setPremiumFinal(tarif.final_price.premium);
        setRenfortFinal(tarif.final_price.renfort);
      }
      setEco(ecoTemp);
      setConfort(confortTemp);
      setPrestige(prestigeTemp);
      setPremium(premiumTemp);
      setRenfort(renfortTemp);
    }
  }, []);

  const onClickboxEconomique = () => {
    if (boxEconomique.current.className === "plan featured") {
      boxEconomique.current.className = "plan";
      document.getElementById("checkEco").checked = false;
    } else {
      boxEconomique.current.className = "plan featured";
      document.getElementById("checkEco").checked = true;
    }
  };

  const onClickboxConfort = () => {
    if (boxConfort.current.className === "plan featured") {
      boxConfort.current.className = "plan";
      document.getElementById("checkConfort").checked = false;
    } else {
      boxConfort.current.className = "plan featured";
      document.getElementById("checkConfort").checked = true;
    }
  };

  function deactivateRenfort(formule, price) {
    let box = document.getElementById("plan" + formule);
    let finalPrice = document.getElementById("beforeRenfort" + formule);
    let buttonRenfort = document.getElementById("buttonRenfort" + formule);

    box.classList.remove("activeRenfortPlan");

    finalPrice.innerHTML = price + "€";
    finalPrice.style.transform = "translate(0, 18%)";

    buttonRenfort.classList.remove("buttonActiveRenfortPlan");
  }
  const onClickbox = (formule, price) => {
    let box = document.getElementById("plan" + formule);
    let check = document.getElementById("check" + formule);
    if (box.classList.contains("featured")) {
      box.classList.remove("featured");
      deactivateRenfort(formule, price);
      check.checked = false;
      return;
    }

    check.checked = true;
    box.classList.add("featured");
  };

  const activeDisplayRenfort = (event, formule, price) => {
    event.stopPropagation();
    let box = document.getElementById("plan" + formule);
    let finalPrice = document.getElementById("beforeRenfort" + formule);
    let buttonRenfort = document.getElementById("buttonRenfort" + formule);
    let check = document.getElementById("check" + formule);

    if (box.classList.contains("activeRenfortPlan")) {
      deactivateRenfort(formule, price);
      return;
    }

    check.checked = true;
    box.classList.add("featured");
    box.classList.add("activeRenfortPlan");
    buttonRenfort.classList.add("buttonActiveRenfortPlan");

    const valueFinal = Number((price + renfortFinal).toFixed(2));
    finalPrice.innerHTML =
      "<span>+ " + renfortFinal + "€" + "</span><br/>" + valueFinal + "€";
    finalPrice.style.transform = "translate(0, 0)";
  };

  const handleCloseRemboursement = () => {
    setOpen(false);
  };

  const submitTarif = () => {
    const oldFormules = [];
    if (boxEconomique.current.classList.contains("featured")) {
      oldFormules.push({ "formule": "ECO" });
    }
    if (boxConfort.current.classList.contains("featured")) {
      oldFormules.push({ "formule": "CONFORT" });
    }
    if (boxPrestige.current.classList.contains("featured")) {
      if (boxPrestige.current.classList.contains("activeRenfortPlan")) {
        oldFormules.push({ "formule": "PRESTIGE", "renfort": true });
      } else oldFormules.push({ "formule": "PRESTIGE", "renfort": false });
    }

    if (boxPremium.current.classList.contains("featured")) {
      if (boxPremium.current.classList.contains("activeRenfortPlan")) {
        oldFormules.push({ "formule": "PREMIUM", "renfort": true });
      } else oldFormules.push({ "formule": "PREMIUM", "renfort": false });
    }

    if (oldFormules.length !== 0) {
      setOpenSnack(false);
      dispatch(Actions.setFormules(oldFormules));
      props.goTo();
    } else {
      setOpenSnack(true);
    }
  };

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 600;

  const parentDivForm = useRef(null);
  useEffect(() => {
    parentDivForm.current.scrollTo(0, 0);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <>
      <p
        className="main-form-title"
        style={{ marginLeft: "9vw", display: "flex" }}
      >
        <b style={{ fontWeight: "400", paddingLeft: "10px" }}>Nos propositions tarifaires</b>
      </p>
      <div
        style={{
          display: "flex",
          textAlign: "right",
          position: "absolute",
          right: "13%",
          top: "2%",
        }}
      >
        <Grid container columnSpacing={3}>
          <Grid item>
            {Tooltip(
              openToolTip1,
              setOpenToolTip1,
              "Information: \nCliquez et sélectionnez la ou les formules qui vous intéressent afin de recevoir les devis détaillés.\nVous pourrez ensuite signer de manière électronique et sécurisée le devis et souscrire directement en ligne.",
              "#013f9c",
              "bottom"
            )}
          </Grid>
          {typeof tarif.under_fifty_one_discount !== "undefined" ?
            <Grid item>
              {Tooltip(
                openToolTip2,
                setOpenToolTip2,
                " - Réduction exceptionnelle  accordée aux moins de 51ans : \nAnnée 1 : -30%\nAnnée 2 : -20%\n Année 3 : -10% \n - 2 mois offerts la 1ère année",
                "#ed1a70",
                "bottom"
              )}
            </Grid> : ""}
        </Grid>

      </div>
      {width < breakpoint ? (
        <>
          <div ref={parentDivForm} style={{ textAlign: "center" }} id="mobileVersionTarif">
            <div style={{ marginTop: "46px" }}>
              <div
                id="buttonTarifDiv"
              >
                <Grid container spacing={0.5}>
                  <Grid item xs={4}>
                    <a
                      className="buttonTarif buttonGD"
                      onClick={() =>
                        window.open(
                          "https://www.unim.asso.fr/unim/conseiller/pdf/formule_toutes.pdf",
                          "_blank"
                        )
                      }
                      style={{ color: "#5f8aaa" }}
                    >
                      <FavoriteIcon
                        sx={{ color: blue[200] }}
                        fontSize="large"
                      />
                      <br />
                      Le tableau de<br />
                      garanties détaillées
                    </a>
                  </Grid>
                  <Grid item xs={4.5}>
                    <a
                      style={{ color: "#5ba9b2", alignSelf: 'center' }}
                      className="buttonTarif buttonExample"
                      href={Exemple}
                      rel='noreferrer'
                      target="_blank"
                    >
                      {/* <img src={Reimbursement} style={{ width: "20%" }} /> */}
                      <VolunteerActivismIcon
                        sx={{ color: cyan[300] }}
                        fontSize="large"
                      />
                      <br />
                      Les exemples <br />
                      de remboursements
                    </a>
                  </Grid>
                  <Grid item xs={3.5}>
                    <a
                      className="buttonTarif buttonBareme"
                      onClick={() =>
                        window.open(
                          "https://www.unim.asso.fr/unim/conseiller/pdf/formule_renfort.pdf",
                          "_blank"
                        )
                      }
                      style={{ color: "#8ba88c" }}
                    >
                      {/* <img src={Heart} style={{ width: "20%" }} /> */}
                      <HealthAndSafetyIcon sx={{ color: green[200] }} fontSize="large" />
                      <br />
                      Barème <br />formule renfort
                    </a>
                  </Grid>
                </Grid>
              </div>
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={-70}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
              >
                <SwiperSlide>
                  {" "}
                  {EconomicTable(
                    boxEconomique,
                    onClickboxEconomique,
                    eco,
                    ecoFinal
                  )}{" "}
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  {ConfortTable(
                    boxConfort,
                    onClickboxConfort,
                    confort,
                    confortFinal
                  )}{" "}
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  {PrestigeTable(
                    boxPrestige,
                    onClickbox,
                    activeDisplayRenfort,
                    prestige,
                    prestigeFinal,
                    true
                  )}{" "}
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  {PremiumTable(
                    boxPremium,
                    onClickbox,
                    activeDisplayRenfort,
                    premium,
                    premiumFinal
                  )}{" "}
                </SwiperSlide>
              </Swiper>
              <button
                id="tarifButton"
                onClick={() => submitTarif()}
                // style={{ marginTop: "20px" }}
                className="button-form"
              >
                Recevoir mon devis détaillé
              </button>
            </div>
          </div>
        </>
      ) : (
        <React.Fragment>
          <div
            ref={parentDivForm}
            style={{ marginTop: "46px" }}
            id="tableComparitif"
          >
            <Grid container justifyContent={"flex-end"} marginBottom={"-70px"} marginTop={"70px"}>
              <Grid container item sm={10.5} justifyContent={"flex-end"}>

                <div
                  className="buttonPDF"
                  style={{
                    // top: "0vw",
                    width: "100%",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={3.5}>
                      <a
                        onClick={() => {
                          window.open(
                            "https://www.unim.asso.fr/unim/conseiller/pdf/formule_toutes.pdf",
                            "_blank"
                          );
                          return;
                        }}
                        style={{ color: "#5f8aaa" }}
                        className="buttonTarif buttonGD"
                      >
                        <FavoriteIcon
                          sx={{ color: blue[200] }}
                          fontSize="large"
                        />
                        <br />
                        Le tableau de<br />
                        garanties détaillées
                      </a>
                    </Grid>
                    <Grid item xs={3.5}>
                      <a
                        style={{ color: "#5ba9b2" }}
                        href={Exemple}
                        rel='noreferrer'
                        target="_blank"
                        className="buttonTarif buttonExample"
                      >
                        {/* <img src={Reimbursement} style={{ width: "20%" }} />*/}
                        <VolunteerActivismIcon
                          sx={{ color: cyan[300] }}
                          fontSize="large"
                        />
                        <br />
                        Les exemples<br />
                        de remboursements
                      </a>
                    </Grid>
                    <Grid item xs={3.5}>
                      <a
                        onClick={() =>
                          window.open(
                            "https://www.unim.asso.fr/unim/conseiller/pdf/formule_renfort.pdf",
                            "_blank"
                          )
                        }
                        style={{ color: "#8ba88c" }}
                        className="buttonTarif buttonBareme"
                      >
                        {/* <img src={Heart} style={{ width: "20%" }} />
                 */}
                        <HealthAndSafetyIcon sx={{ color: green[200] }} fontSize="large" />
                        <br />
                        Barème <br />
                        formule renfort
                      </a>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <br />
            <br />
            <div className="snip1214">
              <div className="boxTarif">
                <div
                  className="plan"
                  style={{
                    borderTop: "10px solid white",
                    borderLeft: "1px solid white",
                    borderRight: "1px solid white",
                    left: "13px",
                    cursor: "context-menu",
                  }}
                >
                  <div className="plan-cost">
                    <h2></h2>
                  </div>
                  <ul className="plan-features">
                    <li
                      style={{
                        display:
                          typeof tarif.under_fifty_one_discount !== "undefined"
                            ? "block"
                            : "none",
                      }}
                      id="cotisationAnuelleLi"
                    >
                      <span className="blocktext">
                        Cotisation de base /an
                      </span>
                    </li>
                    <li
                      id="reducLi"
                      key="reducLi"
                      style={{
                        display: tarif?.under_fifty_one_discount
                          ? "block"
                          : "none",
                      }}
                    >
                      <span
                        className="blocktext"
                        style={{
                          color: "#EC0F6F",
                          marginTop: "1.1vw",
                          marginBottom: "-1.1vw",
                        }}
                      >
                        Réductions
                      </span>
                    </li>
                    <li key="cotisann">
                      <span
                        style={{ height: "86px", paddingTop: "20px" }}
                        className="blocktext"
                      >
                        {" "}
                        {typeof tarif.under_fifty_one_discount !==
                          "undefined" ? (
                          "Cotisation Totale /an"
                        ) : (
                          <>
                            {"Cotisation Totale"} <br /> {"/an"}
                          </>
                        )}
                      </span>
                    </li>
                  </ul>
                </div>
                {EconomicTable(
                  boxEconomique,
                  onClickboxEconomique,
                  eco,
                  ecoFinal
                )}
                {ConfortTable(
                  boxConfort,
                  onClickboxConfort,
                  confort,
                  confortFinal
                )}
                {PrestigeTable(
                  boxPrestige,
                  onClickbox,
                  activeDisplayRenfort,
                  prestige,
                  prestigeFinal
                )}
                {PremiumTable(
                  boxPremium,
                  onClickbox,
                  activeDisplayRenfort,
                  premium,
                  premiumFinal
                )}
              </div>
            </div>
            <br />
            <br />
            <button
              id="tarifButton"
              onClick={() => submitTarif()}
              // style={{ marginTop: "20px" }}
              className="button-form"
            >
              Recevoir mon devis<br /> détaillé
            </button>
          </div>
        </React.Fragment>
      )}
      {/* TODOSNACK */}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        id="snackTarif"
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert
          id="alertTarif"
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Veuillez choisir au moins une formule.
        </Alert>
      </Snackbar>
      <ExampleRemboursement
        open={open}
        handleCloseRemboursement={handleCloseRemboursement}
      />
    </>
  );
}

export default Tarif;
