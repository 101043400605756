function ConfortTable(boxConfort, onClickboxConfort, confort, confortFinal) {
  return (
    <div
      ref={boxConfort}
      onClick={onClickboxConfort}
      // onTouchStart={onClickboxConfort}
      id="planConfort"
      style={{ borderTop: "10px solid #19AAB2" }}
      className="plan"
    >
      <div className="plan-cost">
        <h2>Confort</h2>
        <button
          style={{
            background: "#509995",
            position: "relative",
            visibility: "hidden",
            zIndex: "100",
          }}
          className="renfort"
        >
          Renfort
        </button>
      </div>
      <ul className="plan-features">
        {confort.map((element, id) => (
          
            <li key={"confort_" + id} id={id + "_confort"}>
              {" "}
              {id === 0 ? (
                <>
                  <p className="categoryMobile">Cotisation de base /an</p>
                </>
              ) : null}
              {id === 1 ? (
                <>
                  <p className="categoryMobile"> Réductions</p>
                </>
              ) : null}
              <span  id={id + "_confort_price"}>
                {id === 1 ? "-" : null}
                {element} €
              </span>{" "}
            </li>
          
        ))}
        <li style={{ height: "106px", borderBottom: "none" }} id="2_confort">
          <span
            style={{
              fontSize: "2.6vw",
              color: "#19AAB2",
              transform: "translate(0, 18%)",
            }}
            className="plan-price priceFinalMobile"
          >
            {confortFinal}€
          </span>
        </li>
      </ul>
      <div style={{ background: "#19AAB2" }} className="plan-cost-final">
        <span>
        <input type="checkbox" id="checkConfort"/>
          <label htmlFor="box-2">Choisir</label>
        </span>
      </div>
    </div>
  );
}

export default ConfortTable;
