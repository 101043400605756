function EconomicTable(boxEconomique, onClickboxEconomique, eco, ecoFinal) {

    return <div
      ref={boxEconomique}
      onClick={onClickboxEconomique}
      // onTouchStart={onClickboxEconomique}
      // className="planTarif"
      id="planEco"
      className="plan"
      style={{ borderTop: "10px solid #42A5C8" }}
    >
      <div className="plan-cost">
        <h2>Economique</h2>
        <button
          style={{
            background: "#509995",
            position: "relative",
            visibility: "hidden",
            zIndex: "100",
          }}
          className="renfort"
        >
          Renfort
        </button>
      </div>
      <ul className="plan-features">
        {eco.map((element, id) => (
          
            <li key={"eco_" + id} id={id + "_eco"}>
              {(id === 0) ? <><p id={id + "_cotisation"} className="categoryMobile">Cotisation de base /an</p></> : null}
              {(id === 1) ? <><p id={id + "_reduction"} className="categoryMobile"> Réductions</p></> : null}
             <span id={id + "_eco_price"}>{(id === 1) ? "-" : null}{element} €</span> 
             </li>
          
        ))}
        <li style={{height: "106px", borderBottom: "none"}}  id="2_eco">
          <span style={{ fontSize: "2.6vw" , color: "#42A5C8", transform: "translate(0, 18%)"}} className="plan-price priceFinalMobile">{ecoFinal}€</span>
        </li>
      </ul>
      <div
        style={{ background: "#42A5C8" }}
        className="plan-cost-final"
      >
        <span>
        <input type="checkbox" id="checkEco"/>
          <label htmlFor="box-2">Choisir</label>
        </span>
      </div>
    </div>;
  }

  export default EconomicTable;