
import ConjointDevis from "./ConjointDevis.js";
import ChildrenDevis from "./ChildrenDevis.js";
import React, { useState, useEffect, useRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function PageFamily(props) {
  let { dataBack, conjointRef, childrenRef, isSubmittedFamilyPage, handleClose, open, handleSubmit,  formInputsUser } = props;
  return (
    <>
    <Grid container rowSpacing={{xs:2, sm:7}}>
      {dataBack.insure_conjoint &&
        typeof dataBack.insure_conjoint !== "undefined" && (
          <>
              <ConjointDevis ref={conjointRef} dataBack={dataBack} isSubmittedFamilyPage={isSubmittedFamilyPage} />
              
          </>
        )}

      {dataBack.insure_children &&
        typeof dataBack.insure_children !== "undefined" && (
          <>

            
              <ChildrenDevis ref={childrenRef} dataBack={dataBack} isSubmittedFamilyPage={isSubmittedFamilyPage}/>
            
          </>
        )}
        </Grid>
    </>
  )
}

export default PageFamily;